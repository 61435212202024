'use client'

import { useMenus } from 'header/hooks/useMenus'
import { setMinWidthForStructure } from 'header/utils/menu/minWidthForStructureLPlus'
import { useEffect, useRef } from 'react'
import type { Brand, BrandId } from 'types/brands'

import { BrandEntry } from './BrandEntry/BrandEntry'

import styles from './Brands.module.scss'

export interface BrandMenu {
	id: BrandId
	labelKey: string
	subBrands?: Brand[]
	url: string
}

type BrandsProps = {
	brands: BrandMenu[]
	handleOpenMenu?: () => void
}

export function Brands({ brands, handleOpenMenu }: BrandsProps) {
	const { open } = useMenus()
	const ref = useRef<HTMLUListElement>(null)

	useEffect(() => {
		ref.current && setMinWidthForStructure(ref.current, open)
	}, [ref.current, open])

	if (!brands) {
		return null
	}

	return (
		<ul className={styles.brandsList} ref={ref}>
			{brands.map((brand) => (
				<BrandEntry
					key={brand.id}
					brand={brand}
					handleOpenMenu={handleOpenMenu}
				/>
			))}
		</ul>
	)
}
