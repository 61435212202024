import { useContext } from 'react'

import { MenuStateContext } from './MenuContextLegacy'

export const useMenuStateLegacy = () => {
	const context = useContext(MenuStateContext)

	if (context === undefined) {
		throw new Error('useMenuState must be used within a MenuProvider')
	}

	return context
}
