'use client'

import { useMenus } from 'header/hooks/useMenus'
import { useEffect, useRef, useState } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import { getFirstAndLastElement } from '../../../../utils/menu/focusOrder/getFirstAndLastElement'
import { handleEscapeKeyPress } from '../../../../utils/menu/focusOrder/handleEscapeKeyPress'
import { handleTabKeyPress } from '../../../../utils/menu/focusOrder/handleTabKeyPress'
import { ColumnsSM } from '../Columns/ColumnsSM'
import { SubBrands } from '../SubBrands/SubBrands'
import { TopBar } from '../TopBar/TopBar'

import styles from './StructureSM.module.scss'

export type MenuStates = {
	[key: string]: boolean
}

export function StructureSM() {
	const {
		open,
		brandSelected,
		subBrandSelected,
		updateOpen,
		getBrandSubBrands,
		getBrandMenu,
		hasDataLoaded,
		selectedEntry,
	} = useMenus()

	const subBrands = getBrandSubBrands(brandSelected)
	const { menus, subLabel } = getBrandMenu(brandSelected, subBrandSelected)
	const [reveal, setReveal] = useState<boolean | null>(null)

	const asideRef = useRef<HTMLElement>(null)

	useEffect(() => {
		const asideElement = asideRef.current
		!open && setReveal(null)
		if (open && asideElement && hasDataLoaded) {
			const { firstElement, lastElement } = getFirstAndLastElement(asideElement)

			const tabListener = (event: KeyboardEvent) =>
				handleTabKeyPress(event, firstElement, lastElement)
			const escapeListener = (event: KeyboardEvent) =>
				handleEscapeKeyPress(event, updateOpen)

			asideElement.addEventListener('keydown', tabListener)
			asideElement.addEventListener('keydown', escapeListener)

			return () => {
				asideElement.removeEventListener('keydown', tabListener)
				asideElement.removeEventListener('keydown', escapeListener)
			}
		}
		return undefined
	}, [open, brandSelected, subBrandSelected, hasDataLoaded])

	useEffect(() => {
		if (!selectedEntry && reveal !== null && open) {
			setReveal(true)
		} else {
			setReveal(false)
		}
	}, [selectedEntry])

	return (
		<aside
			ref={asideRef}
			className={cx(
				styles.structure,
				applyStylesIf(open, styles.open),
				applyStylesIf(!open, styles.close),
				applyStylesIf(!!selectedEntry, styles.translateXOut),
				applyStylesIf(!!reveal, styles.translateXIn)
			)}
		>
			<TopBar isSM={true} />
			<div className={cx(styles.content, applyStylesIf(!open, styles.hidden))}>
				{subBrands && <SubBrands subBrands={subBrands} />}
				<ColumnsSM menus={menus} subLabel={subLabel} />
			</div>
		</aside>
	)
}
