import { brandPath } from 'country-configuration/utils/brands/brandPath'
import { useMenus } from 'header/hooks/useMenus'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { Brands } from '../Brands/Brands'
import { HamburgerButton } from '../HamburgerButton/HamburgerButton'

export const OpenMenu = () => {
	const { isLargeOrGreater } = useResponsive()
	const { open, updateOpen } = useMenus()
	const {
		country: { brands: countryBrands, countryISO, languageISO },
	} = useMasterData()
	const href = `/${countryISO.toLocaleLowerCase()}/${languageISO}/h`
	const { t } = useLabels()

	const toggleOpen = () => {
		updateOpen(!open)
	}

	const brands = countryBrands.map((brand) => {
		return {
			...brand,
			url: brandPath({
				brandId: brand.id,
				brands: countryBrands,
				translate: t,
				initialPath: href,
			}),
		}
	})

	return isLargeOrGreater ? (
		<Brands brands={brands} handleOpenMenu={toggleOpen} />
	) : (
		<HamburgerButton handleOpenMenu={toggleOpen} />
	)
}
