import { useContext } from 'react'

import { MenuDispatchContext } from './MenuContextLegacy'

export const useMenuDispatchLegacy = () => {
	const context = useContext(MenuDispatchContext)

	if (context === undefined) {
		throw new Error('useMenuDispatch must be used within a MenuProvider')
	}

	return context
}
