import {
	getFromLocalStorage,
	setToLocalStorage,
} from 'utils/webStorage/localStorage'

export enum SEARCHLOCALSTORAGEKEYS {
	SEARCHKEYWORDS = 'searchedKeywords',
}

interface SearchLocalStorage {
	[SEARCHLOCALSTORAGEKEYS.SEARCHKEYWORDS]: string[] | []
}

function getSearchLocalStorage<K extends SEARCHLOCALSTORAGEKEYS>(
	propertyName: K
): SearchLocalStorage[K] {
	const searchLS = getFromLocalStorage<SearchLocalStorage>('search')

	if (!searchLS) {
		return []
	}

	return searchLS[propertyName]
}

const setSearchLocalStorage = (attribute: string, value: unknown) => {
	const headerLocalStorage = getFromLocalStorage<SearchLocalStorage>('search')

	return setToLocalStorage('search', {
		...headerLocalStorage,
		[attribute]: value,
	})
}

export { getSearchLocalStorage, setSearchLocalStorage }
