'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect } from 'react'
import { useSession } from 'session/src/hooks/useSession'
import { type KeyedMutator } from 'swr'
import { hasBeenLogged } from 'utils/hasBeenLogged'
import { isBot } from 'utils/isBot/IsBot'

import { TOTAL_ITEMS_KEY } from '../constants/constants'
import { useCartDispatch, useCartState } from '../context/CartProvider'
import { getBagItems } from '../services/queries'
import { shoppingCartCache } from '../utils/cache'
import { merge, updateTotalItems } from '../utils/controller'
import { useCartFetcher } from './useCartFetcher'
import { useNotifications } from './useNotifications'

export interface CartDataType {
	findTotalItemsByUser: {
		totalItems: number
		merged: boolean
	}
}

interface UseBagTotalItemsType {
	totalItems: number | undefined
	mutate: KeyedMutator<CartDataType>
	error: Error[] | null
}

export const useBagTotalItems = (): UseBagTotalItemsType => {
	const { data, error, mutate, globalMutate, fetcher } = useCartFetcher<
		CartDataType,
		undefined
	>({
		query: getBagItems,
	})

	const dispatch = useCartDispatch()
	const { showShoppingCart, showPreview } = useCartState()
	const {
		country: { countryISO },
	} = useMasterData()
	const { logged, token, isReady } = useSession()
	const { updateNotification } = useNotifications()

	useEffect(() => {
		if (token) {
			shoppingCartCache(token, countryISO)
		}
		if (data) {
			updateTotalItems(0, globalMutate, dispatch)
		}
	}, [token, countryISO])

	useEffect(() => {
		if (!isReady || !token || isBot()) {
			return
		}

		const handleData = async () => {
			const totalItemsCache =
				await shoppingCartCache().getKeyFromCache(TOTAL_ITEMS_KEY)

			if (hasBeenLogged(logged)) {
				merge({ fetcher, globalMutate })
			} else if (
				totalItemsCache !== undefined &&
				Number.isInteger(totalItemsCache)
			) {
				mutate(
					{
						findTotalItemsByUser: {
							totalItems: totalItemsCache as number,
							merged: data?.findTotalItemsByUser.merged || false,
						},
					},
					{ revalidate: false }
				)
			} else if (!data && !showShoppingCart) {
				mutate() // revalidate
			} else {
				// No action needed in this case
			}
		}

		handleData()
	}, [isReady, token])

	useEffect(() => {
		const onData = async () => {
			if (data?.findTotalItemsByUser?.merged) {
				updateNotification()
				mutate(
					{
						findTotalItemsByUser: {
							...data.findTotalItemsByUser,
							merged: false,
						},
					},
					{ revalidate: false }
				)
			}
			if (Number.isInteger(data?.findTotalItemsByUser?.totalItems) && token) {
				await shoppingCartCache().addKeyToCache(
					TOTAL_ITEMS_KEY,
					data?.findTotalItemsByUser.totalItems
				)
			}
		}

		onData()
	}, [data])

	useEffect(() => {
		dispatch({
			totalItems: data?.findTotalItemsByUser?.totalItems,
		})
	}, [showPreview])

	return {
		totalItems: data?.findTotalItemsByUser?.totalItems,
		error,
		mutate,
	}
}
