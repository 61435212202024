import {
	MenuLegacySectionType,
	type MenuLegacyType,
} from 'header/types/menu/menuLegacy'

import type { SelectedMenusType } from '../../../types'

export const validateSelectedMenus = (
	menus: MenuLegacyType[],
	selectedMenus?: SelectedMenusType
): selectedMenus is SelectedMenusType => {
	if (!selectedMenus) {
		return false
	}

	const selectedMenuBrandsIds = Object.keys(selectedMenus)
	const menuBrandsIds = menus.map((menu) => menu.id)

	const brandsIdsmatched = menuBrandsIds.every((id) =>
		selectedMenuBrandsIds.includes(id)
	)
	const multibrandMenus = menus.filter(
		(menuItem) => menuItem.type === MenuLegacySectionType.Multibrand
	)
	const hasMultibrands = multibrandMenus.length > 0

	if (brandsIdsmatched && hasMultibrands) {
		return multibrandMenus.every((menu) => {
			const { menus: subMenus, id } = menu

			if (!subMenus) {
				return false
			}

			const selectedSubMenuBrandsIds = Object.keys(selectedMenus[id])

			return subMenus.every(
				(subMenu) =>
					subMenu.subBrandId &&
					selectedSubMenuBrandsIds.includes(subMenu.subBrandId)
			)
		})
	}

	return brandsIdsmatched
}
