import stylesCloseButton from 'header/components/Menu/InspirationalMenu/CloseButton/CloseButton.module.scss'
import stylesStructureLPlus from 'header/components/Menu/InspirationalMenu/Structure/StructureLPlus.module.scss'

export function setMinWidthForStructure(
	current: HTMLUListElement,
	open: boolean
) {
	const closeButtonWidth = parseInt(stylesCloseButton.CLOSE_BUTTON_WIDTH, 10)
	const paddingStructure = parseInt(stylesStructureLPlus.PADDING_STRUCTURE, 10)
	const structureMinWidth = parseInt(stylesStructureLPlus.MIN_WIDTH, 10)
	const element = document.getElementById('inspirationalMenu')

	if (!open && current) {
		const brandsWidth = current.offsetWidth
		const topBarWidth = brandsWidth + paddingStructure + closeButtonWidth
		const minWidth =
			topBarWidth < structureMinWidth ? structureMinWidth : topBarWidth

		element?.style.setProperty('--structure-min-width', `${minWidth}px`)
	}
}

export function getPropertyStructureMinWidth() {
	const element = document.getElementById('inspirationalMenu')

	if (!element) {
		return 0
	}

	const getValue = getComputedStyle(element).getPropertyValue(
		'--structure-min-width'
	)

	return parseInt(getValue, 10) || 0
}
