import { useHeaderDispatch } from 'hooks/useHeaderContext/useHeaderDispatch'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import type { MasterData } from 'types/masterData'
import {
	hideOverflows,
	restoreOverflows,
} from 'utils/overflowControl/overflowControl'

import { useMenuDataDispatch } from '../context/MenuContext/useMenuDataDispatch'
import { useMenuDataState } from '../context/MenuContext/useMenuDataState'
import { useMenuStatesDispatch } from '../context/MenuContext/useMenuStatesDispatch'
import { useMenuStatesState } from '../context/MenuContext/useMenuStatesState'
import { type MenuEntry, MenuSectionType } from '../types/menu'

export function useMenus() {
	const {
		country: { brands },
	} = useMasterData()

	const { open, firstOpen, brandSelected, subBrandSelected, selectedEntry } =
		useMenuStatesState()
	const { menus } = useMenuDataState()
	const dispatchStates = useMenuStatesDispatch()
	const dispatchData = useMenuDataDispatch()
	const dispatchHeader = useHeaderDispatch()

	const updateOpen = (newValue: boolean) => {
		if (newValue !== open) {
			updateFirstOpen(true)
			dispatchStates({ open: newValue })
			newValue ? hideOverflows() : restoreOverflows()
		}
	}

	const updateFirstOpen = (newValue: boolean) => {
		if (newValue !== firstOpen) {
			dispatchStates({ firstOpen: newValue })
		}
	}

	const updateBrandSelected = (newValue: MasterData['brand'] | null) => {
		if (newValue === brandSelected) {
			return
		}
		dispatchStates({ brandSelected: newValue })
		dispatchHeader({ brandId: newValue })
		const subBrands = brands?.find((brand) => brand.id === newValue)?.subBrands
		if (subBrands) {
			updateSubBrandSelected(subBrands[0].id)
		} else {
			updateSubBrandSelected(null)
		}
	}

	const updateSubBrandSelected = (newValue: string | null) => {
		if (newValue !== subBrandSelected) {
			dispatchStates({ subBrandSelected: newValue })
		}
	}

	const updateSelectedEntry = (newValue: string | null) => {
		dispatchStates({ selectedEntry: newValue })
	}

	const updateMenus = (newValue?: MenuEntry[]) => {
		if (!menus && (newValue ?? []).length > 0) {
			dispatchData({ menus: newValue })
		}
	}

	const getTopBrands = () => {
		if (!menus) {
			return []
		}
		return menus.map((brand) => {
			return {
				...brand,
				menus: undefined,
			}
		})
	}

	const getBrandSubBrands = (brandId: string | null) => {
		const brandEntry = menus?.find((brand) => brand.id === brandId)

		if (brandEntry?.type === MenuSectionType.Multibrand) {
			return brandEntry?.menus?.map((subBrand) => {
				return {
					...subBrand,
				}
			})
		}

		return undefined
	}

	const getBrandMenu = (brandId: string | null, subBrandId: string | null) => {
		if (subBrandId) {
			const subBrands = getBrandSubBrands(brandId)
			const subBrand = subBrands?.find((item) => item.id === subBrandId)

			return {
				menus: subBrand?.menus,
				subLabel: subBrand?.subLabelId,
			}
		}
		return {
			menus: menus?.find((brand) => brand.id === brandId)?.menus,
			subLabel: undefined,
		}
	}

	return {
		open,
		firstOpen,
		brandSelected,
		subBrandSelected,
		selectedEntry,
		updateMenus,
		updateOpen,
		updateFirstOpen,
		updateBrandSelected,
		updateSubBrandSelected,
		updateSelectedEntry,
		getTopBrands,
		getBrandSubBrands,
		getBrandMenu,
		hasDataLoaded: !!menus,
	}
}
