import { Channel, Device } from 'types/masterData'

export const enum Spelling {
	MNG = 'MNG',
	MANGO = 'MANGO',
}

export const LEGACY_SIZES = {
	[Channel.Shop]: {
		[Device.Desktop]: {
			[Spelling.MANGO]: {
				width: 144,
				height: 26,
			},
			[Spelling.MNG]: {
				width: 91,
				height: 26,
			},
		},
		[Device.Mobile]: {
			[Spelling.MANGO]: {
				width: 80,
				height: 14,
			},
			[Spelling.MNG]: {
				width: 49,
				height: 14,
			},
		},
	},
	[Channel.Outlet]: {
		[Device.Desktop]: {
			[Spelling.MANGO]: {
				width: 206,
				height: 26,
			},
			[Spelling.MNG]: {
				width: 206,
				height: 26,
			},
		},
		[Device.Mobile]: {
			[Spelling.MANGO]: {
				width: 124,
				height: 14,
			},
			[Spelling.MNG]: {
				width: 124,
				height: 14,
			},
		},
	},
}
