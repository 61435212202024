'use client'

import { cloneElement } from 'react'

import { sendGenericEvent } from '../../events/generics/sendGenericEvent'

type EventElementProps = {
	children: React.ReactElement
	eventName: string
	eventData?: Record<string, string>
}

export const EventElement = ({
	children,
	eventData = {},
	eventName,
}: EventElementProps) => {
	const handleClick = () => {
		sendGenericEvent(eventName, eventData)
	}

	return cloneElement(children, {
		onClick: handleClick,
	})
}
