'use client'

import { Button } from 'fukku/Button'
import type { ButtonProps } from 'fukku/Button/Button'
import { useFormContext } from 'react-hook-form'

type FukkuButtonProps = ButtonProps<HTMLButtonElement>

type SubmitButtonProps = {
	id: NonNullable<FukkuButtonProps['id']>
	label: FukkuButtonProps['label']
} & Partial<FukkuButtonProps>

export function SubmitButton({
	id,
	label,
	...buttonAttributes
}: SubmitButtonProps): React.ReactNode {
	const {
		formState: { isSubmitting },
	} = useFormContext()

	return (
		<Button
			buttonAttributes={buttonAttributes}
			id={id}
			isSubmit
			loading={isSubmitting}
		>
			{label}
		</Button>
	)
}
