import type { SelectedMenusType } from 'header/types'
import {
	getFromLocalStorage,
	setToLocalStorage,
} from 'utils/webStorage/localStorage'

export type HeaderLocalStorage = {
	selectedMenus: SelectedMenusType
}

export function getHeaderLocalStorage<K extends keyof HeaderLocalStorage>(
	propertyName: K
): HeaderLocalStorage[K] | undefined {
	const headerLocalStorage = getFromLocalStorage<HeaderLocalStorage>('header')

	if (!headerLocalStorage) {
		return
	}

	return headerLocalStorage[propertyName]
}

export const setHeaderLocalStorage = (attribute: string, value: unknown) => {
	const headerLocalStorage = getFromLocalStorage<HeaderLocalStorage>('header')

	return setToLocalStorage('header', {
		...headerLocalStorage,
		[attribute]: value,
	})
}
