import { ClientLabel } from 'labels/client'
import { cx } from 'utils/cx'

import type { ErrorMessageProps } from '../types'

import styles from './Input.module.scss'
import texts from 'fukku/styles/texts.module.scss'

export function ErrorMessage({
	children,
	id,
	errorPlaceholders,
}: ErrorMessageProps) {
	if (!children) {
		return null
	}

	return (
		<p
			className={cx(texts.bodyS, styles.errorMessage, styles.showError)}
			id={`${id}-error`}
			aria-live='polite'
		>
			<ClientLabel placeholderValues={errorPlaceholders}>
				{children}
			</ClientLabel>
		</p>
	)
}
