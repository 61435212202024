'use client'

import { ClientLink } from 'utils/link/client'

import { useCartUrl } from '../hooks/useCartUrl'

interface CartClientLinkProps {
	id: string
	className?: string
	onClick?: () => void
	linkProps?: Record<string, unknown>
	children: React.ReactNode
}

export function CartClientLink({
	id,
	className,
	onClick,
	linkProps,
	children,
}: CartClientLinkProps): React.ReactNode {
	const url = useCartUrl()

	return (
		<ClientLink
			id={id}
			className={className}
			href={url.href}
			prefetch={false}
			onClick={onClick}
			linkProps={linkProps}
		>
			{children}
		</ClientLink>
	)
}
