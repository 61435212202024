'use client'

import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
import {
	getFromSessionStorage,
	setToSessionStorage,
} from 'utils/webStorage/sessionStorage'

import { PREVIOUS_URL_KEY } from '../constants'

let previousUrl: string | null = null

export const StorePreviousUrl = () => {
	const pathname = usePathname()

	previousUrl = getFromSessionStorage<string>(PREVIOUS_URL_KEY)

	useEffect(() => {
		setToSessionStorage(PREVIOUS_URL_KEY, pathname)
	}, [])

	return null
}

export function getStorePreviousUrl() {
	return previousUrl || ''
}
