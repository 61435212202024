'use client'

import { HoverDelayInspiration } from 'header/constants/hoverDelays'
import { useHover } from 'header/hooks/useHover'
import { useMenus } from 'header/hooks/useMenus'
import type { MenuEntry } from 'header/types/menu'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { applyStylesIf, cx } from 'utils/cx'

import styles from './SubBrands.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface SubBrandProps {
	subBrand: MenuEntry
}

export function SubBrand({ subBrand }: SubBrandProps) {
	const { t } = useLabels()
	const { updateSubBrandSelected, subBrandSelected } = useMenus()

	const { ref } = useHover<HTMLButtonElement>(
		() => handleMouseEnter(subBrand.id),
		subBrand?.id,
		HoverDelayInspiration.SUBBRANDS
	)

	const handleMouseEnter = (subBrandId: string) => {
		updateSubBrandSelected(subBrandId)
	}
	return (
		<li
			className={styles.list}
			key={subBrand.id}
			data-testid={`menu.subBrand.${subBrand.id}`}
		>
			<button
				ref={ref}
				id={subBrand.id}
				className={cx(styles.button, text.uppercaseM)}
				onClick={() => handleMouseEnter(subBrand.id)}
				data-testid={`menu.subBrand.${subBrand.id}.section`}
			>
				<span
					className={cx(
						styles.wrapperText,
						applyStylesIf(subBrand.id === subBrandSelected, styles.selected)
					)}
				>
					{t(subBrand.labelId)}
				</span>
			</button>
		</li>
	)
}
