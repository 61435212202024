import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { ButtonIconTransparent } from 'fukku/Button/Icon/Transparent'
import { CommonGenericEvents } from 'header/constants'
import { brandAdapter } from 'header/constants/analytics/brandAdapter'
import { useMenus } from 'header/hooks/useMenus'
import { IconCloseS1 } from 'icons/components/IconCloseS1'
import { useLabels } from 'labels/hooks/useLabels/useLabels'

import styles from './CloseButton.module.scss'

interface CloseProps {
	action?: () => void
}

export function CloseButton({ action }: CloseProps) {
	const { brandSelected, updateOpen } = useMenus()
	const { t } = useLabels()

	const handleMenuClose = () => {
		action?.()
		updateOpen(false)
		sendGenericEvent(CommonGenericEvents.CLOSE_MENU, {
			brand: brandAdapter[brandSelected as string],
		})
	}

	return (
		<ButtonIconTransparent
			className={styles.closeButton}
			onClick={handleMenuClose}
			aria-label={t('accessibility.icon.cross.close.text')}
			data-testid='menu.close'
		>
			<IconCloseS1 />
		</ButtonIconTransparent>
	)
}
