'use client'

import type { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import type { FeatureFlag } from 'feature-flags/types'
import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import type { PageFeatureFlag } from '../../../types'

export type ClientFeatureFlagLinkProps = {
	featureFlagKey: featureFlagKeys
	children: React.ReactNode
} & Omit<ClientLinkProps, 'withLegacyLocale' | 'href'>

export function ClientFeatureFlagLink<T extends FeatureFlag>({
	featureFlagKey,
	children,
	...linkProps
}: ClientFeatureFlagLinkProps): React.ReactNode {
	const { variables } = useFeatureFlag<T & PageFeatureFlag>(featureFlagKey)

	if (!variables) {
		throw new Error(`Feature flag ${featureFlagKey} variables not found`)
	}

	const href = variables.url
	const withLegacyLocale = variables.isLegacy
	const props: Omit<ClientLinkProps, 'prefetch'> = {
		...linkProps,
		children,
		href,
		withLegacyLocale,
	}

	return <ClientLink {...props} />
}
