const NUMBER_TWO = 2

export function formatDateLegacy(date: Date) {
	const datePart = [date.getDate(), date.getMonth() + 1, date.getFullYear()]
		.map((n) => n.toString().padStart(NUMBER_TWO, '0'))
		.join('')
	const timePart = [date.getHours(), date.getMinutes(), date.getSeconds()]
		.map((n) => n.toString().padStart(NUMBER_TWO, '0'))
		.join('')
	return `${datePart}T${timePart}`
}
