import type { EntryParent, MenuEntry } from 'header/types/menu'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useEffect, useRef, useState } from 'react'

import { useColumnPositionTop } from '../../../../hooks/useColumnPositionTop'
import { Entry } from '../Entry/Entry'

import styles from './ColumnsL.module.scss'

type ColumnsLProps = {
	menus: MenuEntry[] | undefined
	subLabel?: string
	selectedEntries: string[]
	setSelectedEntries: (selectedEntries: string[]) => void
}

export function ColumnsL({
	menus,
	subLabel,
	selectedEntries,
	setSelectedEntries,
}: ColumnsLProps) {
	const [visibleMenus, setVisibleMenus] = useState<(MenuEntry[] | undefined)[]>(
		[menus]
	)
	const [parent, setParent] = useState<EntryParent | undefined>()
	const { t } = useLabels()
	const refWrapper = useRef<HTMLDivElement>(null)
	const columnPosition = useColumnPositionTop(refWrapper)

	const updateSelectedEntry = (entry: MenuEntry, index: number) => {
		if (index === 0) {
			setParent({ id: entry.id, labelId: entry.labelId })
		}

		if (entry.menus) {
			const newVisibleMenus = visibleMenus.slice(0, index + 1)
			newVisibleMenus.push(entry.menus)
			setVisibleMenus(newVisibleMenus)
		}

		const newSelectedEntries = selectedEntries.slice(0, index)
		newSelectedEntries.push(entry.id)
		setSelectedEntries(newSelectedEntries)
	}

	useEffect(() => {
		if (menus) {
			setVisibleMenus([menus])
			setSelectedEntries([])
		}
	}, [menus])

	return (
		<div
			className={styles.columnsWrapper}
			ref={refWrapper}
			style={
				{
					'--column-start-top': `${columnPosition}`,
				} as React.CSSProperties
			}
		>
			{visibleMenus?.map((column, index) => {
				const key = `column${index}`
				const dataTestIdCategory = index === 0 ? 'section' : 'family'
				const dataTestIdColumn =
					parent && index !== 0 ? `menu.family.${parent.id}` : null
				return (
					<ul
						className={styles.column}
						key={key}
						data-testid={dataTestIdColumn}
					>
						{index === 0 && subLabel && (
							<p className={styles.subLabel}>{t(subLabel)}</p>
						)}
						{column?.map((entry) => {
							const isEntrySelected = selectedEntries[index]
								? selectedEntries[index] === entry.id
								: undefined

							const analyticsCategory =
								index !== 0 && parent
									? {
											section: parent.labelId,
											subsection: entry.labelId,
										}
									: { section: entry.labelId }
							return (
								<li
									key={entry.id}
									data-testid={`menu.${dataTestIdCategory}.${entry.id}`}
								>
									<Entry
										section={entry}
										isSelected={isEntrySelected}
										dataTestIdCategory={dataTestIdCategory}
										analyticsCategory={analyticsCategory}
										onClick={() => updateSelectedEntry(entry, index)}
									/>
								</li>
							)
						})}
					</ul>
				)
			})}
		</div>
	)
}
