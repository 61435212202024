'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { env } from 'utils/envManager'

import { MENU_SERVICE_URL } from '../../../constants'
import { formatDateLegacy } from '../formatDateLegacy'

export function useMenuService() {
	const {
		customDate,
		test,
		country: { countryISO: country, languageISO: language },
	} = useMasterData()
	function getMenuServiceUrlClient(): string {
		const newService = `${env.NEXT_PUBLIC_SITE_BASE_URL}${MENU_SERVICE_URL}/v1/menu/${
			env.NEXT_PUBLIC_CHANNEL
		}/${country.toUpperCase()}/${language}${test ? '/test' : ''}`

		const url = new URL(newService)

		if (customDate) {
			url.searchParams.append('configDate', formatDateLegacy(customDate))
		}

		return url.href
	}

	return { getMenuServiceUrlClient }
}
