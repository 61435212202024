import { useContext } from 'react'

import { MenuStateContext } from './MenuStatesContext'

export const useMenuStatesState = () => {
	const context = useContext(MenuStateContext)

	if (context === undefined) {
		throw new Error('useMenuState must be used within a MenuProvider')
	}

	return context
}
