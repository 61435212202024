// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconLanguageL = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='language-l'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 24 24'
		>
			<path d='M13.305 2.104c-.429-.056-.862-.095-1.305-.095s-.876.039-1.304.095h-.001C5.797 2.747 2 6.938 2 12.009c0 5.514 4.486 10 10 10s10-4.486 10-10c0-5.07-3.797-9.262-8.695-9.905m6.855 6.132h-2.748a13.45 13.45 0 0 0-2.759-4.827 9.04 9.04 0 0 1 5.507 4.827M17 12c0 1.098-.16 2.108-.414 3.025H12.49v-5.79h4.161c.213.848.348 1.768.348 2.765zm-4.14 8.965c-.121.012-.246.012-.369.019v-4.96h3.764c-1.038 2.744-2.85 4.468-3.395 4.941m-5.118-4.94h3.75v4.958c-.118-.007-.237-.007-.353-.018-.546-.471-2.36-2.191-3.397-4.94M7 12c0-.996.135-1.916.35-2.764h4.141v5.789H7.412A11.3 11.3 0 0 1 7 12m4.118-8.946c.123-.012.249-.012.373-.02v5.202H7.646c1.003-2.853 2.878-4.663 3.472-5.182m1.373 5.182V3.034c.13.007.263.007.392.02.594.518 2.471 2.323 3.473 5.182zM9.347 3.409a13.45 13.45 0 0 0-2.76 4.827H3.84a9.04 9.04 0 0 1 5.507-4.827M3.442 9.236h2.876A12.4 12.4 0 0 0 6 12c0 1.095.145 2.1.376 3.025H3.53A9 9 0 0 1 3 12.009c0-.968.158-1.899.442-2.773m.515 6.789h2.711c.7 2.056 1.815 3.6 2.699 4.589a9.04 9.04 0 0 1-5.41-4.59zm10.676 4.589a13.5 13.5 0 0 0 2.699-4.59h2.71a9.04 9.04 0 0 1-5.409 4.59m5.836-5.59h-2.845c.231-.923.376-1.93.376-3.024 0-.994-.124-1.91-.318-2.764h2.876c.284.874.442 1.805.442 2.773a9 9 0 0 1-.531 3.016z' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}
