'use client'

import { Logo } from 'fukku/Logo'
import { LEGACY_SIZES, Spelling } from 'header/constants/logo'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { Channel, Device } from 'types/masterData'

export function LogoSizesWrapper() {
	const {
		isShop,
		country: { mngLogo },
	} = useMasterData()
	const { isLargeOrGreater } = useResponsive()

	const device = isLargeOrGreater ? Device.Desktop : Device.Mobile
	const channel = isShop ? Channel.Shop : Channel.Outlet
	const logoSpelling = mngLogo ? Spelling.MNG : Spelling.MANGO
	const size = LEGACY_SIZES[channel][device][logoSpelling]

	return <Logo size={size} />
}
