export const ANCHOR_TAG_OPENING_LEGACY = '<a name="PrivacyPolicyShort">'
export const ANCHOR_TAG_OPENING = '<a href="#PrivacyPolicyShort">'
export const ANCHOR_TAG_CLOSING = '</a>'
export const EXPAND_POLICY_TRIGGER_TESTID = 'privacyPolicy.expand'
export const EXPANDED_POLICY_LINK_TESTID = 'privacyPolicy.linkToRGPD'
export const PRIVACY_POLICY_PAGE_PATHNAME = '/privacy-policy'
export const TERMS_CONDITIONS_PAGE_PATHNAME = '/terms-and-conditions'
export const EXPAND_CHECKBOX_ID = 'expand-checkbox'
export const EXPAND_CONTENT_ID = 'expand-content'
export const BACKINSTOCK_POLICY_ID = 'bacnInStock-policy'
export const NEWSLETTER_POLICY_ID = 'newsletter-policy'
export const CHECKOUT_REGISTRY_POLICY_ID = 'checkoutRegistry'
export const REGISTRY_POLICY_ID = 'registry'
export const MLY_REGISTRY_POLICY_ID = 'mlyRegistry'
export const NO_MLY_REGISTRY_POLICY_ID = 'noMlyRegistry'

export enum POLICY_TYPES {
	GENERAL = 'general',
	ANDROID = 'android',
	IOS = 'ios',
}
