'use client'

import type { MenuEntry } from 'header/types/menu'

import { SubBrand } from './SubBrand'

import styles from './SubBrands.module.scss'

interface SubBrandsProps {
	subBrands: MenuEntry[]
}

export function SubBrands({ subBrands }: SubBrandsProps) {
	return (
		<div className={styles.suBrandsWrapper}>
			<ul className={styles.subBrands}>
				{subBrands.map((subBrand) => (
					<SubBrand key={subBrand.id} subBrand={subBrand} />
				))}
			</ul>
		</div>
	)
}
