import { env } from 'utils/envManager'

export const isGenesisCatalog = /\/[a-z]{2}\/[a-z]{2}(-[a-z]{2})?\/c\//

export function getMenuUrl(href: string) {
	if (href.match(isGenesisCatalog)) {
		return href
	}
	return `${env.NEXT_PUBLIC_SITE_BASE_URL}${href}`
}
