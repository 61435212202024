export enum CommonGenericEvents {
	CLICK_HEADER = 'click_header',
	CLICK_MENU = 'click_menu',
	CLOSE_MENU = 'close_menu',
	DISPLAY_HEADER = 'display_header',
}

export enum CommonGenericLabels {
	CLOSE_SUBMENU = '_close',
}

export enum SectionParameters {
	FAVORITES = 'favorites',
	LOGO = 'logo',
	MENU = 'menu',
	MY_ACCOUNT = 'myaccount',
}

export enum SubSectionParameters {
	HELP = 'help',
	LOGIN = 'login',
	LOGOUT = 'logout',
	LOYALTY = 'MLY',
	MY_PURCHASES = 'mypurchases',
	REGISTER = 'register',
}

export enum Levels {
	BRAND = 'brand',
	SUB_BRAND = 'subbrand',
	SECTION = 'section',
	SUB_SECTION = 'subsection',
}
