import { Channel } from 'types/masterData'

export enum LOGO_ALT_TEXT {
	Mango = 'MANGO',
	Outlet = 'MANGO Outlet',
	Mng = 'MNG',
}

export const logoAltText: Record<string, Record<string, LOGO_ALT_TEXT>> = {
	[Channel.Shop]: {
		mango: LOGO_ALT_TEXT.Mango,
		mng: LOGO_ALT_TEXT.Mng,
	},
	[Channel.Outlet]: {
		mango: LOGO_ALT_TEXT.Outlet,
		mng: LOGO_ALT_TEXT.Mng,
	},
}
