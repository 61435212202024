export enum HoverDelayInspiration {
	BRANDS = 80,
	SUBBRANDS = 80,
	ENTRIES = 50,
	OUTSIDE = 50,
	TIME_PROTECTION_HOVER_OUTSIDE = 200,
	DELAY_BACK_ACTION = 100,
}

export enum HoverDelayLegacy {
	BRANDS = 50,
	ENTRIES = 50,
}
