export function getFirstAndLastElement(divElement: HTMLElement): {
	firstElement: HTMLElement
	lastElement: HTMLElement
} {
	const focusableElements = divElement.querySelectorAll(
		'button, [href], [tabindex]:not([tabindex="-1"])'
	)
	const firstElement = focusableElements[0] as HTMLElement
	const lastElement = focusableElements[
		focusableElements.length - 1
	] as HTMLElement

	return { firstElement, lastElement }
}
