'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import type { EntryClickMenuEventData, MenuEntry } from 'header/types/menu'
import { useLabels } from 'labels/hooks/useLabels/useLabels'

import { CommonGenericEvents } from '../../../../constants'
import { ButtonInspirationalLink } from '../InspirationalLink/ButtonInspirationalLink'

type EntrySubmenuProps = {
	section: MenuEntry
	className: string
	isSelected: boolean | undefined
	dataTestIdCategory: string
	eventData: EntryClickMenuEventData
	onClick: () => void
}
export function EntrySubmenu({
	section,
	className,
	isSelected,
	dataTestIdCategory,
	eventData,
	onClick,
}: EntrySubmenuProps) {
	const { id, labelId } = section
	const { t } = useLabels()

	const handleClick = () => {
		sendGenericEvent(CommonGenericEvents.CLICK_MENU, eventData)
		onClick()
	}

	return (
		<ButtonInspirationalLink
			className={className}
			onClick={handleClick}
			data-testid={`menu.${dataTestIdCategory}.${id}.family`}
			active={isSelected}
			aria-expanded={isSelected}
		>
			{t(labelId)}
		</ButtonInspirationalLink>
	)
}
