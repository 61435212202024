import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { CommonGenericEvents } from 'header/constants'
import { useMenus } from 'header/hooks/useMenus'
import { IconHamburger } from 'icons/components/IconHamburger'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { OutletBrand, ShopBrand } from 'types/brands'

import styles from './HamburgerButton.module.scss'

type HamburgerButtonProps = {
	handleOpenMenu: () => void
}
export const HamburgerButton = ({ handleOpenMenu }: HamburgerButtonProps) => {
	const { updateBrandSelected } = useMenus()
	const { t } = useLabels()
	const { brand: pageBrand, isShop } = useMasterData()

	const defaultBrand = isShop ? ShopBrand.she : OutletBrand.outlet
	const brandToOpen = pageBrand || defaultBrand

	const handleButtonClick = () => {
		updateBrandSelected(brandToOpen)
		sendGenericEvent(CommonGenericEvents.CLICK_HEADER, {
			section: 'menu',
		})
		handleOpenMenu()
	}

	return (
		<button
			data-testid='menu.burger'
			aria-label={t('accessibility.header.menu.icon')}
			onClick={handleButtonClick}
			className={styles.button}
		>
			<IconHamburger color='content-1' className={styles.menuIcon} />
		</button>
	)
}
