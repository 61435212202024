export {
	CommonGenericEvents,
	SectionParameters,
	SubSectionParameters,
} from './analytics/analytics'

export const MENU_SERVICE_URL = '/ws-menu'

export const USER_SUB_MENU_WIDTH = 398
export const USER_ICON_WIDTH = 78
export const DEFAULT_PADDING_RIGHT = 32
export const RENDERED_ARROW_WIDTH = 20
