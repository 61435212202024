import { getBrandTranslationKeys } from 'country-configuration/utils/brands/getBrandTranslationKeys'
import { ButtonIconTransparent } from 'fukku/Button/Icon/Transparent'
import { useMenus } from 'header/hooks/useMenus'
import { IconKeyboardReturn } from 'icons/components/IconKeyboardReturn'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import type { BrandId } from 'types/brands'
import { cx } from 'utils/cx'

import { CloseButton } from '../CloseButton/CloseButton'

import styles from './Breadcrumb.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface BreadcrumbProps {
	breadcrumbTitle: string
	prev: () => void
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
	breadcrumbTitle,
	prev,
}) => {
	const { brandSelected, subBrandSelected, updateSelectedEntry } = useMenus()
	const { t } = useLabels()
	const { labelKey: brandLabelId } = getBrandTranslationKeys(
		(subBrandSelected || brandSelected) as BrandId
	)

	const action = () => {
		updateSelectedEntry(null)
	}

	return (
		<section className={styles.breadcrumbs} data-testid='menu.submenu.header'>
			<ButtonIconTransparent
				className={styles.prevButton}
				onClick={prev}
				aria-label={t('accessibility.icon.back.text')}
				data-testid='menu.subMenu.back'
			>
				<IconKeyboardReturn width={16} rotateRtl />
			</ButtonIconTransparent>

			<span className={styles.textWrapper}>
				<span className={cx(text.uppercaseL, styles.breadcrumbTitle)}>
					{t(breadcrumbTitle)}
				</span>
				<span className={cx(text.uppercaseS, styles.brand)}>
					{t(brandLabelId)}
				</span>
			</span>

			<CloseButton action={action} />
		</section>
	)
}
