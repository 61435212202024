import { TimeOut } from 'header/constants/timeOuts'
import type { MenuEntry } from 'header/types/menu'
import { useEffect, useRef, useState } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import { useMenus } from '../../../../hooks/useMenus'
import { getFirstAndLastElement } from '../../../../utils/menu/focusOrder/getFirstAndLastElement'
import { handleEscapeKeyPress } from '../../../../utils/menu/focusOrder/handleEscapeKeyPress'
import { handleTabKeyPress } from '../../../../utils/menu/focusOrder/handleTabKeyPress'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { ColumnsSM } from '../Columns/ColumnsSM'

import styles from './InteriorMenu.module.scss'

export interface InteriorMenuProps {
	title: string
	menus: MenuEntry[]
	parentLabelId: string
	goBackAction: () => void
}

export function InteriorMenu({
	title,
	menus,
	parentLabelId,
	goBackAction,
}: InteriorMenuProps) {
	const { updateOpen, selectedEntry } = useMenus()
	const divRef = useRef<HTMLDivElement>(null)
	const [revealOut, setRevealOut] = useState(false)

	const prev = () => {
		setRevealOut(true)
		setTimeout(() => {
			goBackAction()
		}, TimeOut.BACK_ACTION)
	}

	useEffect(() => {
		const divElement = divRef.current
		if (divElement) {
			const { firstElement, lastElement } = getFirstAndLastElement(divElement)

			const tabListener = (event: KeyboardEvent) =>
				handleTabKeyPress(event, firstElement, lastElement)
			const escapeListener = (event: KeyboardEvent) =>
				handleEscapeKeyPress(event, updateOpen)

			divElement.addEventListener('keydown', tabListener)
			divElement.addEventListener('keydown', escapeListener)

			return () => {
				divElement.removeEventListener('keydown', tabListener)
				divElement.removeEventListener('keydown', escapeListener)
			}
		}
		return undefined
	}, [])

	return (
		<div
			className={cx(
				styles.interiorMenu,
				applyStylesIf(!!selectedEntry, styles.open),
				applyStylesIf(revealOut, styles.close)
			)}
			data-testid='menu.submenu'
			ref={divRef}
		>
			<Breadcrumb breadcrumbTitle={title} prev={prev} />
			<ColumnsSM menus={menus} parentLabelId={parentLabelId} />
		</div>
	)
}
