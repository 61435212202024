import { IconCloseMedium } from 'icons/components/IconCloseMedium'

import type { ClearOptionButtonProps } from '../types'

import styles from './Input.module.scss'

export function ClearOptionButton({
	ariaLabelClearOption,
	onClear: handleClear,
	shouldRender,
}: ClearOptionButtonProps) {
	if (!shouldRender) {
		return null
	}

	return (
		<button
			aria-expanded
			aria-label={ariaLabelClearOption}
			className={styles.clearButton}
			onClick={handleClear}
			type='button'
			data-testid='clearInput'
		>
			<IconCloseMedium />
		</button>
	)
}
