import { ServiceErrorCodes } from '../../constants'
import type { NewsletterSubscriptionServiceError } from '../../types/service'

export const isFieldsError = (
	error: Error
): error is NewsletterSubscriptionServiceError => {
	const defaultStatus = ServiceErrorCodes.Generic
	const defaultCause = { status: defaultStatus }
	const cause =
		typeof error.cause === 'object' && error.cause ? error.cause : defaultCause
	const response = 'response' in cause && cause.response
	const status = 'status' in cause && cause.status

	return !!response && status === ServiceErrorCodes.Fields
}
