import { useLabels } from 'labels/hooks/useLabels/useLabels'

export function useMenuLabels() {
	const { t } = useLabels()
	const getTranslatedKeys = (key: string, label?: string): string => {
		if (label) {
			return label
		}
		return t(key)
	}
	return {
		menuT: getTranslatedKeys,
	}
}
