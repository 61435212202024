'use client'

import { TimeOut } from 'header/constants/timeOuts'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { usePathname, useSearchParams } from 'next/navigation'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'
import type { MasterData } from 'types/masterData'

type MenuState = {
	open: boolean
	firstOpen: boolean
	brandSelected: MasterData['brand']
	subBrandSelected: null | string
	selectedEntry: null | string
}

const INITIAL_STATE: MenuState = {
	open: false,
	firstOpen: false,
	brandSelected: null,
	subBrandSelected: null,
	selectedEntry: null,
}

type MenuDispatch = (newState: Partial<MenuState>) => void

const INITIAL_DISPATCH: MenuDispatch = () => {
	/* */
}

type MenuProviderProps = {
	children: React.ReactNode
}

export const MenuStateContext = createContext<MenuState>(INITIAL_STATE)
export const MenuDispatchContext = createContext<MenuDispatch>(INITIAL_DISPATCH)

export const MenuStatesProvider = ({ children }: MenuProviderProps) => {
	const { brand: pageBrand } = useMasterData()
	const [state, setState] = useState({
		...INITIAL_STATE,
		brandSelected: pageBrand,
	})
	const dispatch = useCallback(
		(newState: Partial<MenuState>) =>
			setState((prevState) => ({ ...prevState, ...newState })),
		[]
	)

	const pathname = usePathname()
	const searchParams = useSearchParams()
	const didRender = useRef(false)

	useEffect(() => {
		if (didRender.current) {
			dispatch({ open: false })
		} else {
			didRender.current = true
		}
	}, [pathname, searchParams])

	useEffect(() => {
		const brand = state.open ? state.brandSelected : pageBrand

		setTimeout(
			() => {
				dispatch({ brandSelected: brand })
			},
			state.open ? 0 : TimeOut.CLOSE_BRAND_DEFAULT
		)
	}, [state.open])

	return (
		<MenuStateContext.Provider value={state}>
			<MenuDispatchContext.Provider value={dispatch}>
				{children}
			</MenuDispatchContext.Provider>
		</MenuStateContext.Provider>
	)
}
