'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { CommonGenericEvents } from 'header/constants'
import { useCorrectUrlNewResponse } from 'header/hooks/useCorrectUrlNewResponse'
import type { EntryClickMenuEventData, MenuEntry } from 'header/types/menu'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { usePathname } from 'next/navigation'

import { ClientInspirationalLink } from '../InspirationalLink/ClientInspirationalLink'

type EntryLinkProps = {
	section: MenuEntry
	className: string
	isSelected: boolean | undefined
	dataTestIdCategory: string
	eventData: EntryClickMenuEventData
}

export function EntryLink({
	section,
	isSelected,
	dataTestIdCategory,
	eventData,
}: EntryLinkProps) {
	const { id, labelId } = section
	const { t } = useLabels()
	const url = useCorrectUrlNewResponse(section)
	const pathname = usePathname()

	const handleLinkClick = () => {
		sendGenericEvent(CommonGenericEvents.CLICK_MENU, eventData)
	}

	return (
		<ClientInspirationalLink
			href={url || ''}
			isLocalizedPath
			linkProps={{
				'data-testid': `menu.${dataTestIdCategory}.${id}.link`,
				'aria-current': pathname === url ? 'page' : undefined,
			}}
			active={isSelected}
			onClick={handleLinkClick}
		>
			{t(labelId)}
		</ClientInspirationalLink>
	)
}
