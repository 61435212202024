import type { MenuEntry } from '../../types/menu'

export const hideInSections = (brands?: MenuEntry[]): MenuEntry[] => {
	if (!brands || brands.length === 0) {
		return []
	}

	return brands.map((brand) => {
		const { menus } = brand
		if (!menus) {
			return brand
		}
		return {
			...brand,
			menus: hideInRecursive(menus),
		}
	})
}

const hideInRecursive = (
	menuEntry: MenuEntry[] | undefined
): MenuEntry[] | undefined => {
	if (!menuEntry) {
		return undefined
	}

	return menuEntry
		.map((menu) => {
			const { hideIn } = menu

			if (!hideIn || (!hideIn.desktop && !hideIn.mobile)) {
				return {
					...menu,
					menus: hideInRecursive(menu.menus),
				} as MenuEntry
			}

			return null
		})
		.filter((item): item is MenuEntry => item !== null)
}
