'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { ButtonIconTransparent } from 'fukku/Button/Icon/Transparent'
import { CommonGenericEvents } from 'header/constants'
import { brandAdapter } from 'header/constants/analytics/brandAdapter'
import { HoverDelayInspiration } from 'header/constants/hoverDelays'
import { useHover } from 'header/hooks/useHover'
import { useMenus } from 'header/hooks/useMenus'
import { IconDownSmall } from 'icons/components/IconDownSmall'
import { IconUpSmall } from 'icons/components/IconUpSmall'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import type { MasterData } from 'types/masterData'

import { ButtonInspirationalLink } from '../../InspirationalLink/ButtonInspirationalLink'
import { ClientInspirationalLink } from '../../InspirationalLink/ClientInspirationalLink'
import type { BrandMenu } from '../Brands'

import styles from './BrandEntry.module.scss'

type BrandEntryProps = {
	brand: BrandMenu
	handleOpenMenu?: () => void
}

export function BrandEntry({ brand, handleOpenMenu }: BrandEntryProps) {
	const { isLargeOrGreater } = useResponsive()
	const { open, brandSelected, updateBrandSelected } = useMenus()
	const { t } = useLabels()
	const pathname = usePathname()

	const isBrandActive = brandSelected ? brandSelected === brand.id : undefined
	const isBrandMenuOpen = open && isBrandActive

	const { id, labelKey: labelId, url } = brand
	const label = t(labelId)

	const hoverUpdateBrandSelected = () => {
		updateBrandSelected(id as MasterData['brand'])
	}

	const { ref: refBrandEntry, isHovering } = useHover<HTMLLIElement>(
		hoverUpdateBrandSelected,
		id,
		HoverDelayInspiration.BRANDS
	)

	const handleButtonClick = () => {
		if (!isLargeOrGreater) {
			sendGenericEvent(CommonGenericEvents.CLICK_MENU, {
				brand: brandAdapter[id],
				from: brandAdapter[brandSelected as string],
			})
		}
		updateBrandSelected(id)
	}

	const handleLinkClick = () => {
		sendGenericEvent(CommonGenericEvents.CLICK_MENU, {
			brand: brandAdapter[id],
		})
	}

	useEffect(() => {
		if (isHovering && handleOpenMenu) {
			handleOpenMenu()
		}
	}, [isHovering])

	return (
		<li
			className={styles.brandLi}
			ref={isLargeOrGreater ? refBrandEntry : undefined}
			data-testid={`menu.brand.${id}.hover`}
		>
			{isLargeOrGreater ? (
				<>
					<ClientInspirationalLink
						href={url || ''}
						isLocalizedPath
						className={styles.brandEntry}
						linkProps={{
							'data-testid': `menu.brand.${id}`,
							rel: 'follow',
							'aria-current': pathname === url ? 'page' : undefined,
						}}
						onClick={handleLinkClick}
						active={isBrandActive}
					>
						{label}
					</ClientInspirationalLink>
					<ButtonIconTransparent
						className={styles.accessibilityButton}
						onClick={handleButtonClick}
						aria-label={label}
						aria-selected={isBrandActive}
						aria-expanded={isBrandMenuOpen}
					>
						{isBrandMenuOpen ? <IconUpSmall /> : <IconDownSmall />}
					</ButtonIconTransparent>
				</>
			) : (
				<ButtonInspirationalLink
					className={styles.brandEntry}
					data-testid={`menu.brand.${id}`}
					onClick={handleButtonClick}
					active={isBrandActive}
					aria-selected={isBrandActive}
				>
					{label}
				</ButtonInspirationalLink>
			)}
		</li>
	)
}
