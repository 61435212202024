'use client'

import { useFormContext } from 'form-components'
import { MODAL_TYPE, Modal, type ModalAPI } from 'fukku/Modal'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useEffect, useRef } from 'react'

import type { FormValues } from '../../../types/form'
import { ErrorBody } from './ErrorBody'

type GenericErrorProps = {
	submitHandler(values: FormValues): Promise<void>
	resetValues(): void
}

export function GenericError({
	submitHandler,
	resetValues,
}: GenericErrorProps): React.ReactNode {
	const { t } = useLabels()

	const {
		formState: { errors },
		handleSubmit,
	} = useFormContext<FormValues>()
	const modalRef = useRef<ModalAPI>(null)

	const closeModal = (): void => {
		modalRef.current?.close()
	}

	const retryHandler = async (): Promise<void> => {
		handleSubmit(submitHandler)()
		closeModal()
	}

	const cancelHandler = (): void => {
		resetValues()
		closeModal()
	}

	useEffect(() => {
		if (!!errors?.root) {
			modalRef.current?.show()
		}
	}, [errors.root])

	return (
		<Modal
			name='newsletter-generic-modal'
			ref={modalRef}
			type={MODAL_TYPE.L}
			title={t('error.connection.title')}
		>
			<ErrorBody retryHandler={retryHandler} cancelHandler={cancelHandler} />
		</Modal>
	)
}
