'use client'

import { featureFlagKeys } from 'feature-flags/constants/featureFlags'

import {
	ClientFeatureFlagLink,
	type ClientFeatureFlagLinkProps,
} from '../../components/featureFlagLink/client'
import { StorePreviousUrl } from '../shared/StorePreviousUrl'

type LoginClientFeatureFlagLinkProps = Omit<
	ClientFeatureFlagLinkProps,
	'featureFlagKey'
>

export function LoginClientLink({
	children,
	...linkProps
}: LoginClientFeatureFlagLinkProps) {
	return (
		<>
			<StorePreviousUrl />
			<ClientFeatureFlagLink
				{...linkProps}
				featureFlagKey={featureFlagKeys.LoginPage}
			>
				{children}
			</ClientFeatureFlagLink>
		</>
	)
}
