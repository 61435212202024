import { PageType } from 'types/analytics'

export enum CommonGenericEvents {
	CLOSE = 'close_search',
	OPENED = 'searcher_opened',
	CHANGE_BRAND = 'change_brand',
}

export enum LocationParams {
	BAR = 'search_bar',
	ICON = 'header_icon',
	SEARCHER = 'searcher',
}

export const ANALYTICS_PAGE_TITLE = `${PageType.Search}_results`
