'use client'

import { useAnimationMenu } from 'header/hooks/useAnimationMenu'
import { useMenus } from 'header/hooks/useMenus'
import { useStructureHeight } from 'header/hooks/useStructureHeight'
import { useEffect, useRef } from 'react'
import { cx } from 'utils/cx'

import { getFirstAndLastElement } from '../../../../utils/menu/focusOrder/getFirstAndLastElement'
import { handleEscapeKeyPress } from '../../../../utils/menu/focusOrder/handleEscapeKeyPress'
import { handleTabKeyPress } from '../../../../utils/menu/focusOrder/handleTabKeyPress'
import { ColumnsL } from '../Columns/ColumnsL'
import { SubBrands } from '../SubBrands/SubBrands'
import { TopBar } from '../TopBar/TopBar'

import styles from './StructureLPlus.module.scss'

export function StructureLPlus() {
	const {
		open,
		brandSelected,
		subBrandSelected,
		updateOpen,
		getBrandSubBrands,
		getBrandMenu,
		hasDataLoaded,
	} = useMenus()

	const { refContent, widthAside, selectedEntries, setSelectedEntries } =
		useAnimationMenu(brandSelected)

	const subBrands = getBrandSubBrands(brandSelected)
	const { menus, subLabel } = getBrandMenu(brandSelected, subBrandSelected)

	const asideRef = useRef<HTMLElement>(null)
	const heightStructure = useStructureHeight(asideRef)

	useEffect(() => {
		const asideElement = asideRef.current
		if (open && asideElement && hasDataLoaded) {
			const { firstElement, lastElement } = getFirstAndLastElement(asideElement)

			const tabListener = (event: KeyboardEvent) =>
				handleTabKeyPress(event, firstElement, lastElement)
			const escapeListener = (event: KeyboardEvent) =>
				handleEscapeKeyPress(event, updateOpen)

			asideElement.addEventListener('keydown', tabListener)
			asideElement.addEventListener('keydown', escapeListener)

			return () => {
				asideElement.removeEventListener('keydown', tabListener)
				asideElement.removeEventListener('keydown', escapeListener)
			}
		}
		return undefined
	}, [open, brandSelected, subBrandSelected, hasDataLoaded, selectedEntries])

	return (
		<aside
			id='structureLPlus'
			ref={asideRef}
			className={cx(
				styles.structure,
				open && styles.open,
				!open && styles.close
			)}
			style={
				{
					'--structure-width': `${widthAside}px`,
					'--structure-height': `${heightStructure}px`,
				} as React.CSSProperties
			}
		>
			<TopBar isSM={false} />
			<div className={styles.content} ref={refContent}>
				{subBrands && <SubBrands subBrands={subBrands} />}
				<ColumnsL
					menus={menus}
					subLabel={subLabel}
					selectedEntries={selectedEntries}
					setSelectedEntries={setSelectedEntries}
				/>
			</div>
		</aside>
	)
}
