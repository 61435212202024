'use client'

import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

export function Responsive({
	onSmall = false,
	onMedium = false,
	onLarge = false,
	onLessThanLarge = false,
	onLargeOrGreater = false,
	onLessThanExtraLarge = false,
	onExtraLargeOrGreater = false,
	children,
}: {
	onSmall?: boolean
	onMedium?: boolean
	onLarge?: boolean
	onLessThanLarge?: boolean
	onLargeOrGreater?: boolean
	onLessThanExtraLarge?: boolean
	onExtraLargeOrGreater?: boolean
	children: React.ReactNode
}) {
	const {
		isSmall,
		isMedium,
		isLarge,
		isLargeOrGreater,
		isLessThanLarge,
		isExtraLargeOrGreater,
		isLessThanExtraLarge,
	} = useResponsive()
	if (
		[
			onExtraLargeOrGreater && isExtraLargeOrGreater,
			onLessThanExtraLarge && isLessThanExtraLarge,
			onLargeOrGreater && isLargeOrGreater,
			onLessThanLarge && isLessThanLarge,
			onLarge && isLarge,
			onMedium && isMedium,
			onSmall && isSmall,
		].some((condition) => condition)
	) {
		return children
	}
	return null
}
