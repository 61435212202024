export function handleTabKeyPress(
	event: KeyboardEvent,
	firstElement: HTMLElement,
	lastElement: HTMLElement
) {
	if (event.key === 'Tab') {
		if (event.shiftKey && document.activeElement === firstElement) {
			event.preventDefault()
			lastElement.focus()
		}
		if (!event.shiftKey && document.activeElement === lastElement) {
			event.preventDefault()
			firstElement.focus()
		}
	}
}
