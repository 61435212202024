'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { useHeaderDispatch } from 'hooks/useHeaderContext/useHeaderDispatch'
import { IconCloseBig } from 'icons/components/IconCloseBig'
import { IconSearchL } from 'icons/components/IconSearchL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import dynamic from 'next/dynamic'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { Keyboard } from 'types/keyboard'

import { CommonGenericEvents, LocationParams } from '../../constants/analytics'
import {
	SEARCHLOCALSTORAGEKEYS,
	getSearchLocalStorage,
} from '../../utils/searchLocalStorage'

import styles from './HeaderSearchBox.module.scss'

const SearchMenu = dynamic(() =>
	import('./SearchMenu/SearchMenu').then((mod) => mod.SearchMenu)
)

export function HeaderSearchBox(): React.ReactNode {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [userHasSearched, setUserHasSearched] = useState<boolean>(false)
	const dispatch = useHeaderDispatch()
	const { t } = useLabels()
	const header = useRef<HTMLDivElement>()
	const recentSearch = getSearchLocalStorage(
		SEARCHLOCALSTORAGEKEYS.SEARCHKEYWORDS
	)
	const pathname = usePathname()
	const searchParams = useSearchParams()

	const handleShow = (isUserInteraction = true) => {
		if (!isUserInteraction) {
			setUserHasSearched(true)
			return
		}

		setIsOpen(!isOpen)
		header.current?.classList.toggle(styles.shadow)

		if (isOpen) {
			sendGenericEvent(CommonGenericEvents.CLOSE)
		} else {
			sendGenericEvent(CommonGenericEvents.OPENED, {
				location: LocationParams.ICON,
				show_recentsearch: recentSearch.length > 0 ? 'yes' : 'no',
				show_recentlyview: 'no',
			})
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if ((e.key === Keyboard.Enter || e.key === Keyboard.Space) && !isOpen) {
			// We need to prevent the default behavior of the button because
			// when the button is pressed using keyboard it triggers the click event
			// which removes the class from the body to control the outline
			e.preventDefault()
			handleShow()
		}

		if (e.key === Keyboard.Tab && isOpen) {
			e.preventDefault()
		}
	}

	useEffect(() => {
		const headerSelector = document.querySelector('header')
		if (headerSelector) {
			header.current = headerSelector as HTMLDivElement
		}
	}, [])

	useEffect(() => {
		if (isOpen) {
			handleShow(false)
		}
	}, [pathname, searchParams])

	useEffect(() => {
		dispatch({ isSearchOpen: isOpen })
	}, [isOpen])

	return (
		<>
			{isOpen && (
				<SearchMenu handleShow={handleShow} userHasSearched={userHasSearched} />
			)}
			<button
				type='button'
				className={styles.button}
				data-testid='header.userMenu.search.button'
				onClick={() => handleShow()}
				onKeyDown={handleKeyDown}
				aria-label={t('accessibility.header.search.icon')}
				rel='nofollow'
			>
				{isOpen ? (
					<IconCloseBig className={styles.icon} />
				) : (
					<IconSearchL className={styles.icon} />
				)}

				<span className={styles.text}>
					{isOpen ? t('header.search.cancel.button') : t('header.search.text')}
				</span>
			</button>
		</>
	)
}
