import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { env } from 'utils/envManager'

export const FULLPAGE_PATH = '/cart'

export function useCartUrl(): URL {
	const {
		country: { countryISO, languageISO },
	} = useMasterData()
	const baseUrl = `/${countryISO.toLowerCase()}/${languageISO.toLowerCase()}${FULLPAGE_PATH}`

	return new URL(baseUrl, env.NEXT_PUBLIC_CURRENT_ORIGIN)
}
