import { Channel } from 'types/masterData'

export enum LOGO_NAME {
	Mango = 'logo-mango',
	Outlet = 'logo-outlet',
	Mng = 'logo-mng',
}

export const logoName: Record<string, Record<string, LOGO_NAME>> = {
	[Channel.Shop]: {
		mango: LOGO_NAME.Mango,
		mng: LOGO_NAME.Mng,
	},
	[Channel.Outlet]: {
		mango: LOGO_NAME.Outlet,
		mng: LOGO_NAME.Mng,
	},
}
