import type { Channel } from 'types/masterData'

export type MenuLegacyType = {
	id: string
	catalogId?: string
	subBrandId?: string
	label: string
	labelId: string
	subLabel?: string
	subLabelId?: string
	link?: string
	menus?: MenuLegacyType[]
	type?: MenuLegacySectionType
	analyticLabel: string
	image?: MenuLegacyImage
	css?: string[]
}

export enum MenuLegacySectionType {
	Brand = 'BRAND',
	Multibrand = 'MULTIBRAND',
	Colecciones = 'colecciones',
	Destacados = 'destacados',
	Sastreria = 'sastreria',
}

export type MenuLegacyImage = {
	alt?: string
	src: string
	fontColor?: string
	analitycLabel?: string
}

export interface MenuServiceLegacy {
	menus: MenuLegacyType[]
	shop: Channel
	version: string
}
