import { useHeaderDispatch } from 'hooks/useHeaderContext/useHeaderDispatch'
import type { MasterData } from 'types/masterData'

import { useMenuDispatchLegacy } from '../context/MenuContextLegacy/useMenuDispatchLegacy'
import { useMenuStateLegacy } from '../context/MenuContextLegacy/useMenuStateLegacy'

export function useMenusLegacy() {
	const { open, hasOpened, brandSelected } = useMenuStateLegacy()
	const dispatch = useMenuDispatchLegacy()
	const dispatchHeader = useHeaderDispatch()

	const updateOpen = (newValue: boolean) => {
		dispatch({ open: newValue })
	}

	const updateHasOpened = (newValue: boolean) => {
		dispatch({ hasOpened: newValue })
	}

	const updateBrandSelected = (newValue: MasterData['brand']) => {
		dispatch({ brandSelected: newValue })
		dispatchHeader({ brandId: newValue })
	}

	return {
		open,
		hasOpened,
		brandSelected,
		updateOpen,
		updateHasOpened,
		updateBrandSelected,
	}
}
