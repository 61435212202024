export const CURRENT_LOGGED_STATUS_KEY = 'shoppingCart.isLogged'

/**
 * Check against localStorage `CURRENT_LOGGED_STATUS_KEY` key if the user has just logged in.
 * This method is used to check if the user has just logged in and if so, we need to merge the cart.
 */
export const hasBeenLogged = (isLogged: boolean): boolean => {
	const wasLoggedBefore = JSON.parse(
		localStorage.getItem(CURRENT_LOGGED_STATUS_KEY) ?? 'false'
	)

	if (wasLoggedBefore !== isLogged) {
		localStorage.setItem(CURRENT_LOGGED_STATUS_KEY, String(!!isLogged))
	}

	return !wasLoggedBefore && isLogged
}
