import {
	type MenuEntry,
	MenuSectionType,
	type MenuService,
} from 'header/types/menu'
import {
	MenuLegacySectionType,
	type MenuLegacyType,
	type MenuServiceLegacy,
} from 'header/types/menu/menuLegacy'
import { Channel, Device } from 'types/masterData'

import { brandAdapter } from '../../constants/analytics/brandAdapter'

const adaptType = (
	type?: MenuSectionType
): MenuLegacySectionType | undefined => {
	switch (type) {
		case MenuSectionType.Brand:
			return MenuLegacySectionType.Brand
		case MenuSectionType.Multibrand:
			return MenuLegacySectionType.Multibrand

		case MenuSectionType.Featured:
			return MenuLegacySectionType.Destacados
		case MenuSectionType.Collections:
			return MenuLegacySectionType.Colecciones
		default:
			return undefined
	}
}

const adaptLink = ({
	device,
	FflagEditActivation,
	FflagCatalogActivation,
	legacyUrl,
	link,
	style,
	type,
}: {
	device: Device
	FflagEditActivation: boolean
	FflagCatalogActivation: boolean
	legacyUrl?: string
	link?: string
	style?: string[]
	type?: string
}): string | undefined => {
	if (style?.includes('header') && device === Device.Desktop) {
		return undefined
	}

	const featureFlagMap: { [key: string]: boolean } = {
		[MenuSectionType.Brand]: true,
		[MenuSectionType.Multibrand]: true,
		[MenuSectionType.Edit]: FflagEditActivation,
		[MenuSectionType.Catalog]: FflagCatalogActivation,
	}

	if (type && featureFlagMap[type]) {
		return link
	}

	return legacyUrl
}

const adaptAnalyticsLabel = (
	id: string,
	labelId: string,
	type?: MenuSectionType
) => {
	if (type === MenuSectionType.Brand || type === MenuSectionType.Multibrand) {
		return brandAdapter[id as keyof typeof brandAdapter]
	}
	return labelId
}

const addHeader = (
	menus: MenuEntry[],
	device: Device,
	isMultibrandOrBrand: boolean
) => {
	const findHeader = menus.find((section) => {
		if (!section.style) {
			return false
		}
		return section.style?.includes('header')
	})
	return !findHeader && device === Device.Desktop && !isMultibrandOrBrand
}

const adaptMenus = (
	section: MenuEntry,
	device: Device,
	sectionIsMultibrand: boolean,
	sectionIsBrand: boolean,
	FflagEditActivation: boolean,
	FflagPlpActivation: boolean
): MenuLegacyType[] => {
	const addHeaderSection = addHeader(
		section.menus ?? [],
		device,
		sectionIsMultibrand || sectionIsBrand
	)
	const adaptedMenus = adaptSection(
		section.menus ?? [],
		device,
		FflagEditActivation,
		FflagPlpActivation,
		sectionIsMultibrand
	)

	return addHeaderSection
		? [
				{
					id: section.id,
					label: '',
					analyticLabel: section.labelId,
					labelId: section.labelId,
				},
				...adaptedMenus,
			]
		: adaptedMenus
}

const adaptSection = (
	menu: MenuEntry[],
	device: Device,
	FflagEditActivation: boolean,
	FflagCatalogActivation: boolean,
	isMultibrandMenu = false
): MenuLegacyType[] => {
	return menu.reduce((acc, section) => {
		if (!section.hideIn?.[device] || !section.hideIn) {
			acc.push({
				id: section.id,
				type: adaptType(section.type),
				label: '',
				labelId: section.labelId,
				link: adaptLink({
					device,
					FflagEditActivation,
					FflagCatalogActivation,
					legacyUrl: section.legacyUrl,
					link: section.url,
					style: section.style,
					type: section.type,
				}),
				catalogId: section.catalogId,
				css: section.style,
				analyticLabel: adaptAnalyticsLabel(
					section.id,
					section.labelId,
					section.type
				),
				subLabelId: section.subLabelId,
				image: section.images?.[device],
				subBrandId:
					section.type === MenuSectionType.Brand && isMultibrandMenu
						? section.id
						: undefined,
				menus: section.menus
					? adaptMenus(
							section,
							device,
							section.type === MenuSectionType.Multibrand,
							section.type === MenuSectionType.Brand,
							FflagEditActivation,
							FflagCatalogActivation
						)
					: undefined,
			})
		}
		return acc
	}, [] as MenuLegacyType[])
}

export function adapterNewMenuService(
	data: MenuService,
	device: Device,
	FflagEditActivation: boolean,
	FflagCatalogActivation: boolean
): MenuServiceLegacy {
	return {
		menus: adaptSection(
			data.menus,
			device,
			FflagEditActivation,
			FflagCatalogActivation
		),
		shop: Channel.Shop,
		version: 'version',
	}
}
