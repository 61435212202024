import type { PlaceholderSrOnlyProps } from '../types'

import fukkuStyles from 'fukku/styles/classes.module.scss'

export function PlaceholderSrOnly({ placeholder }: PlaceholderSrOnlyProps) {
	if (!placeholder) {
		return null
	}

	return <span className={fukkuStyles.srOnly}>{placeholder}</span>
}
