'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { CommonGenericEvents, SectionParameters } from 'header/constants'
import dynamic from 'next/dynamic'
import { useRef, useState } from 'react'

import { AccountIcon } from '../Account'

const AccountMenu = dynamic(() =>
	import('header/components/UserTools/Account/AccountMenu/AccountMenu').then(
		(mod) => mod.AccountMenu
	)
)

export const WrapperAccountMenu = ({
	hasFavorites,
}: {
	hasFavorites: boolean
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const refAccountMenu = useRef<HTMLDivElement>(null)

	const open = () => {
		if (!isOpen) {
			sendGenericEvent(CommonGenericEvents.DISPLAY_HEADER, {
				section: SectionParameters.MY_ACCOUNT,
			})
		}
		setIsOpen(true)
	}

	const close = () => setIsOpen(false)

	return (
		<>
			<AccountIcon open={open} close={close} refAccountMenu={refAccountMenu} />
			{isOpen && (
				<AccountMenu
					open={open}
					close={close}
					refAccountMenu={refAccountMenu}
					hasFavorites={hasFavorites}
				/>
			)}
		</>
	)
}
