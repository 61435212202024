'use client'

import { type CountryFieldValidationsResponse } from 'country-configuration/services/fieldValidations'
import { createContext, useContext } from 'react'

export const FieldValidationsContext = createContext<
	CountryFieldValidationsResponse | undefined
>(undefined)

interface FieldValidationsProviderProps {
	fieldValidations: CountryFieldValidationsResponse
	children: React.ReactNode
}

export function FieldValidationsProvider({
	fieldValidations,
	children,
}: FieldValidationsProviderProps): React.ReactNode {
	return (
		<FieldValidationsContext.Provider value={fieldValidations}>
			{children}
		</FieldValidationsContext.Provider>
	)
}

export const useFieldValidations = (): CountryFieldValidationsResponse => {
	const fieldValidation = useContext(FieldValidationsContext)
	if (!fieldValidation) {
		throw new Error(
			`[FieldValidationsProvider hook] - useFieldValidations must be used within a <FieldValidationsProvider>. 
			<FieldValidationsProviderWrapper> may be useful, it internally fetch country-field-validations and places <FieldValidationsProvider> wrapping all children.`
		)
	}
	return fieldValidation
}
