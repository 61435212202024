import { type RefObject, useEffect } from 'react'
import { useHoverIntent } from 'react-use-hoverintent'

export type HoverEntryCallBackArgs<T> = {
	isHovering: boolean
	id: string
	ref: RefObject<HTMLElement & T>
	delay: number
}

export function useHover<T>(
	updateSelectedEntry: (id: string) => void,
	id: string,
	delay: number
) {
	const [isHovering, ref] = useHoverIntent<T>({
		interval: delay,
	})

	useEffect(() => {
		if (isHovering) {
			updateSelectedEntry(id)
		}
	}, [isHovering])

	return { ref, isHovering }
}
