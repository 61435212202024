import { env } from 'utils/envManager'
import { url } from 'utils/url'

import type {
	NewsletterSubscriptionServiceParams,
	NewsletterSubscriptionServiceResponse,
} from '../types/service'

const NEWSLETTER_SERVICE_ENDPOINT = url(
	`${env.NEXT_PUBLIC_SITE_BASE_URL}/ws-newsletter/subscription`
)

export const newsletterSubscriptionService = async (
	payload: NewsletterSubscriptionServiceParams
): Promise<NewsletterSubscriptionServiceResponse | never> => {
	const response = await fetch(NEWSLETTER_SERVICE_ENDPOINT, {
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
		body: JSON.stringify(payload),
		next: { revalidate: 0 },
	})

	const responseJson = await response.json()

	if (response.ok) {
		return responseJson as NewsletterSubscriptionServiceResponse
	}

	const fieldErrors = {
		errors: responseJson.errorFields,
	}

	throw Error(
		`Newsletter service error with status ${response.status} ${response.statusText}.`,
		{
			cause: {
				status: response.status,
				response: fieldErrors,
			},
		}
	)
}
