'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'

import styles from './EnvMessage.module.scss'

export function EnvMessage() {
	const { customDate, test } = useMasterData()
	return (
		(customDate || test) && (
			<span className={styles.envMessage}>
				{customDate &&
					`${customDate.toLocaleString('es-ES', {
						dateStyle: 'short',
						timeStyle: 'short',
					})}`}
				{customDate && test && ' | '}
				{test && 'Test'}
			</span>
		)
	)
}
