'use client'

import { ClientLabel } from 'labels/client'
import { useCallback, useState } from 'react'
import { cx } from 'utils/cx'

import {
	EXPAND_CHECKBOX_ID,
	EXPAND_CONTENT_ID,
	EXPAND_POLICY_TRIGGER_TESTID,
	TERMS_CONDITIONS_PAGE_PATHNAME,
} from '../../constants/privacyPolicy'

import styles from './ExpandableTextByLink.module.scss'
import text from 'fukku/styles/texts.module.scss'

type ExpandableTextByLinkProps = {
	id?: string
	className?: string
	contentBeforeTrigger?: React.ReactNode
	contentAfterTrigger?: React.ReactNode
	contentToExpand: React.ReactNode
	trigger: string
}
export function ExpandableContent({
	id,
	className = '',
	contentAfterTrigger = null,
	contentBeforeTrigger = null,
	contentToExpand,
	trigger,
}: ExpandableTextByLinkProps): React.ReactNode {
	const [expanded, setExpanded] = useState(false)
	const contentToExpandId = `${id}-${EXPAND_CONTENT_ID}`
	const placeholderValues = {
		termsAndConditions: TERMS_CONDITIONS_PAGE_PATHNAME,
	}

	const handleExpand = useCallback(() => {
		setExpanded((isExpanded) => !isExpanded)
	}, [])

	return (
		<div className={cx(styles.expandableContent, className)}>
			<p id={id} className={styles.resetContent}>
				<ClientLabel>{contentBeforeTrigger}</ClientLabel>
				<button
					id={EXPAND_CHECKBOX_ID}
					className={cx(styles.expander, text.bodySRegularUnderline)}
					onClick={handleExpand}
					aria-expanded={expanded}
					aria-controls={expanded ? contentToExpandId : undefined}
					data-testid={`${id}.${EXPAND_POLICY_TRIGGER_TESTID}`}
				>
					{trigger}
				</button>
				<ClientLabel placeholderValues={placeholderValues}>
					{contentAfterTrigger}
				</ClientLabel>
			</p>
			{expanded && (
				<div
					id={contentToExpandId}
					className={styles.contentToExpand}
					aria-live='polite'
				>
					{contentToExpand}
				</div>
			)}
		</div>
	)
}
