'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { CommonGenericEvents, SectionParameters } from 'header/constants'
import { useMenusLegacy } from 'header/hooks/useMenusLegacy'
import {
	MenuLegacySectionType,
	type MenuLegacyType,
	type MenuServiceLegacy,
} from 'header/types/menu/menuLegacy'
import { checkSelectedMenu } from 'header/utils/menu/selectedMenus/checkSelectedMenu'
import { useHeaderState } from 'hooks/useHeaderContext/useHeaderState'
import { IconCloseMedium } from 'icons/components/IconCloseMedium'
import { IconHamburger } from 'icons/components/IconHamburger'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useEffect, useRef } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import type { BrandId } from 'types/brands'
import { cx } from 'utils/cx'

import { Brands } from './components/Brands/Brands'

import styles from './MenuAdaptive.module.scss'
import text from 'fukku/styles/texts.module.scss'

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions*/

type MenuAdaptiveProps = {
	data: MenuServiceLegacy
}

export function MenuAdaptive({ data }: MenuAdaptiveProps) {
	const { menus } = data
	const {
		open,
		brandSelected,
		updateOpen,
		updateHasOpened,
		updateBrandSelected,
	} = useMenusLegacy()
	const { t } = useLabels()
	const { isLargeOrGreater, isLessThanLarge } = useResponsive()
	const Icon = open ? IconCloseMedium : IconHamburger
	const label = open ? 'menu.close.icon' : 'menu.icon'
	const brandsRef: React.RefObject<HTMLUListElement> = useRef(null)

	const { isSearchOpen } = useHeaderState()

	const getAnalyticsLabelBrand = () => {
		const menuBrand = menus?.find(
			(menu) =>
				(menu?.type === MenuLegacySectionType.Brand ||
					menu?.type === MenuLegacySectionType.Multibrand) &&
				menu.id === brandSelected
		)
		return menuBrand ? menuBrand.analyticLabel : null
	}

	const handleClick = () => {
		updateOpen(!open)
		updateBrandSelected(brandSelected || (menus[0].id as BrandId))

		if (!open) {
			sendGenericEvent(CommonGenericEvents.CLICK_HEADER, {
				section: SectionParameters.MENU,
			})
		} else {
			sendGenericEvent(CommonGenericEvents.CLOSE_MENU, {
				brand: getAnalyticsLabelBrand(),
			})
			updateHasOpened(false)
		}
	}

	useEffect(() => {
		if (isLargeOrGreater && menus) {
			checkSelectedMenu(menus)
		}
	}, [menus, isLargeOrGreater])

	useEffect(() => {
		if (isSearchOpen) {
			updateOpen(false)
		}
	}, [isSearchOpen])

	if (!menus) {
		return null
	}

	return (
		<>
			{isLessThanLarge && (
				<button
					data-testid='menu.burger'
					onClick={handleClick}
					type='button'
					className={styles.button}
				>
					<Icon color='content-1' className={styles.menuIcon} />
					<span className={text.bodyS}>{t(label)}</span>
				</button>
			)}
			<div
				className={cx(
					styles.menuWrapper,
					isLessThanLarge && open && styles.open
				)}
			>
				<nav className={styles.nav} data-testid='menu.brands'>
					<ul className={styles.menu} id='brandsMenu' ref={brandsRef}>
						{menus.map((brand: MenuLegacyType) => (
							<Brands key={brand.id} brandEntry={brand} brandsRef={brandsRef} />
						))}
					</ul>
				</nav>
			</div>
		</>
	)
}
