import { getPropertyStructureMinWidth } from 'header/utils/menu/minWidthForStructureLPlus'
import { useEffect, useRef, useState } from 'react'

import StrutureStyle from 'header/components/Menu/InspirationalMenu/Structure/StructureLPlus.module.scss'

export function useAnimationMenu(brandSelected: string | null) {
	const refContent = useRef<HTMLDivElement>(null)
	const structureMinWidth = Math.max(
		refContent?.current?.clientWidth ?? 0,
		getPropertyStructureMinWidth()
	)
	const [widthAside, setWidthAside] = useState<number>(structureMinWidth)
	const [selectedEntries, setSelectedEntries] = useState<string[]>([])

	const calculateWidth = () => {
		const current = refContent.current
		if (!current) {
			return
		}

		const paddingContent = parseInt(StrutureStyle.PADDING_CONTENT, 10)

		setWidthAside(
			Math.max(current.clientWidth + paddingContent, structureMinWidth)
		)
	}

	useEffect(() => {
		calculateWidth()
	}, [selectedEntries, brandSelected])

	return {
		refContent,
		widthAside,
		selectedEntries,
		setSelectedEntries,
	}
}
