'use client'

import { BUTTON_VARIANT } from 'fukku/Button/Button.types'
import { applyStylesIf, cx } from 'utils/cx'
import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import styles from './InspirationalLink.module.scss'

export const enum LINK_SIZE {
	M = 'medium',
	L = 'large',
}

type ClientInspirationalLinkProps = ClientLinkProps & {
	size?: LINK_SIZE
	variant?: BUTTON_VARIANT
	active?: boolean
}

export function ClientInspirationalLink(
	props: ClientInspirationalLinkProps
): React.ReactNode {
	const {
		children,
		active,
		variant = BUTTON_VARIANT.DEFAULT,
		size = LINK_SIZE.M,
		className,
		...rest
	} = props

	return (
		<ClientLink
			{...rest}
			className={cx(
				className,
				styles[variant],
				styles[size],
				applyStylesIf(active === true, styles.active),
				applyStylesIf(active === false, styles.unselected)
			)}
		>
			{children}
		</ClientLink>
	)
}
