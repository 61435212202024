'use client'

import { useHeaderDispatch } from 'hooks/useHeaderContext/useHeaderDispatch'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { usePathname, useSearchParams } from 'next/navigation'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'
import type { MasterData } from 'types/masterData'

import stylesHeader from 'header/components/Legacy/Header/Header.module.scss'

type MenuState = {
	open: boolean
	hasOpened: boolean
	brandSelected: MasterData['brand']
	closeActionOnce: boolean
}

const INITIAL_STATE: MenuState = {
	open: false,
	hasOpened: false,
	brandSelected: null,
	closeActionOnce: false,
}

type MenuDispatch = (newState: Partial<MenuState>) => void

const INITIAL_DISPATCH: MenuDispatch = () => {
	/* */
}

type MenuProviderProps = {
	children: React.ReactNode
}

export const MenuStateContext = createContext<MenuState>(INITIAL_STATE)
export const MenuDispatchContext = createContext<MenuDispatch>(INITIAL_DISPATCH)

export const MenuProviderLegacy = ({ children }: MenuProviderProps) => {
	const [state, setState] = useState(INITIAL_STATE)
	const dispatch = useCallback(
		(newState: Partial<MenuState>) =>
			setState((prevState) => ({ ...prevState, ...newState })),
		[]
	)
	const dispatchHeader = useHeaderDispatch()
	const { brand: pageBrand } = useMasterData()
	const pathname = usePathname()
	const searchParams = useSearchParams()
	const didRender = useRef(false)

	const updateWhenClosing = () => {
		dispatch({ hasOpened: false, open: false })
	}

	useEffect(() => {
		if (!state.closeActionOnce) {
			dispatch({ closeActionOnce: true })
			const addAnimation = () => {
				updateWhenClosing()
			}
			const header = document.getElementsByClassName(stylesHeader.header)[0]
			header?.addEventListener('mouseleave', addAnimation)
		}
	}, [])

	useEffect(() => {
		if (!state.open) {
			dispatch({ brandSelected: pageBrand })
			dispatchHeader({ brandId: pageBrand })
		}
	}, [state.open])

	useEffect(() => {
		if (didRender.current) {
			dispatch({ open: false })
		} else {
			didRender.current = true
		}
	}, [pathname, searchParams])

	return (
		<MenuStateContext.Provider value={state}>
			<MenuDispatchContext.Provider value={dispatch}>
				{children}
			</MenuDispatchContext.Provider>
		</MenuStateContext.Provider>
	)
}
