// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconLogoMango = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='logo-mango'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 2000 350'
		>
			<path d='m223.2 166.7 29.8 46.2 60.1-93.3v206.6h51.7V25.7H312zM78.1 25.7H25.8v300.5h51.9V119.6L195.4 303l30-46.8zM675.7 233l47.9 93.2h62.3L629.5 25.7h-56.2l-.1.1 78.4 159.3H547.5l27.1-51.9-28.3-56.5-131.8 249.5h58.3l49.3-93.1zm455.5-207.3h-51.9v145.9l51.9 50.8zm-250.3 0h-52.7v300.5h51.9V101.6l212.5 224.6h38.6v-36.5zm612.6 83.4c-24.7-25-62.8-41-105.5-41-68.2 0-124.5 40.8-133.5 93.7h-57.1c7.5-82.2 93.7-142.7 191.3-142.7 58 0 110.2 21.1 145.3 55zm-110.3 175.1c-56.9 0-105.4-30.1-123.4-72.1h-58.2c19.3 72.5 95 120.8 184.9 120.8 20.9 0 44.9-2 64.8-6.8 45-10.9 94.2-39.4 94.2-84.4v-79.9h-162.8l-.1 50.3h110.9v22.8c0 25.2-38.7 39.8-62.3 44.9-12.7 2.8-29.1 4.4-42.2 4.4zm591-108c0-89.6-83.5-159.1-186-159.1-99.3 0-186.5 66.3-186.5 154.3 0 83 68.5 149.5 160.7 160v-50.2c-60.4-9.7-106-53.6-106-106.5 0-60 58.7-108.6 131-108.6 72.4 0 131 48.7 131 108.6 0 52.5-44.9 96.1-104.6 106.3v50.3c88.7-10.5 160.4-74.5 160.4-155.1' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}
