import { useContext } from 'react'

import { MenuDataDispatchContext } from './MenuDataContext'

export const useMenuDataDispatch = () => {
	const context = useContext(MenuDataDispatchContext)

	if (context === undefined) {
		throw new Error('useMenuDispatch must be used within a MenuProvider')
	}

	return context
}
