'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { CommonGenericEvents } from 'header/constants'
import { HoverDelayLegacy } from 'header/constants/hoverDelays'
import { useHover } from 'header/hooks/useHover'
import { useMenuLabels } from 'header/hooks/useMenuLabels'
import { useMenusLegacy } from 'header/hooks/useMenusLegacy'
import type { MenuLegacyType } from 'header/types/menu/menuLegacy'
import { getMenuUrl } from 'header/utils/menu/getMenuUrl'
import dynamic from 'next/dynamic'
import { type FocusEvent, useRef, useState } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import type { BrandId } from 'types/brands'
import { Keyboard } from 'types/keyboard'
import { cx } from 'utils/cx'
import { ClientLink } from 'utils/link/client'

import { BrandsAccesibiltyButton } from './BrandsAccesibiltyButton'

import styles from './Brands.module.scss'
import text from 'fukku/styles/texts.module.scss'

const BrandsSubmenu = dynamic(() =>
	import('./BrandsSubmenu').then((mod) => mod.BrandsSubmenu)
)

interface BrandsProps {
	brandEntry: MenuLegacyType
	brandsRef: React.RefObject<HTMLUListElement>
}

export function Brands({ brandEntry, brandsRef }: BrandsProps) {
	const {
		id,
		link = '',
		css,
		label,
		labelId,
		menus,
		type,
		image,
		analyticLabel,
	} = brandEntry
	const { isLargeOrGreater } = useResponsive()
	const {
		open,
		brandSelected,
		updateOpen,
		updateHasOpened,
		updateBrandSelected,
	} = useMenusLegacy()
	const analyticEventData = {
		brand: analyticLabel,
	}
	const [isFocused, setIsFocused] = useState(false)
	const [isKeyboard, setIsKeyboard] = useState(false)
	const { menuT } = useMenuLabels()
	const checkBrandSelected = brandSelected === id
	const labelBrand = menuT(labelId, label)

	const handleClick = (brand: string) => {
		updateOpen(true)
		if (brand !== brandSelected) {
			updateBrandSelected(brand as BrandId)
			sendGenericEvent(CommonGenericEvents.CLICK_MENU, analyticEventData)
		}
	}

	const handleMouseEnter = (brand: string | null) => {
		setIsFocused(false)
		updateBrandSelected(brand as BrandId)
		if (!open) {
			updateOpen(true)
		} else {
			updateHasOpened(true)
		}
	}

	const handleLinkClick = () => {
		sendGenericEvent(CommonGenericEvents.CLICK_MENU, analyticEventData)
	}

	const handleOpenWithKeyboard = (brand: string) => {
		updateBrandSelected(brand as BrandId)
		updateHasOpened(true)
		updateOpen(open && brand === brandSelected ? false : true)
	}

	const handleBlurWithKeyboard = (event: FocusEvent<HTMLLIElement>) => {
		if (!ref.current?.contains(event.relatedTarget as Node)) {
			setIsFocused(false)
		}
	}

	const handleFocus = () => {
		if (isKeyboard) {
			setIsFocused(true)
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent) => {
		setIsKeyboard(true)

		if (e.key === Keyboard.Escape) {
			updateOpen(false)
			setIsFocused(false)
		}
	}

	const refDropdown = useRef<HTMLButtonElement>(null)
	const { ref } = useHover<HTMLLIElement>(
		handleMouseEnter,
		id,
		HoverDelayLegacy.BRANDS
	)

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<li
			ref={ref}
			id={id}
			className={styles.brand}
			data-testid={`menu.brand.${id}.hover`}
			onMouseDown={() => setIsKeyboard(false)}
			onKeyDown={(e) => handleKeyDown(e)}
			onFocus={handleFocus}
			onBlur={handleBlurWithKeyboard}
		>
			{isLargeOrGreater ? (
				<ClientLink
					href={getMenuUrl(link)}
					className={cx(
						text.bodyMRegular,
						styles.link,
						css && css.join(''),
						checkBrandSelected && styles.selected
					)}
					linkProps={{
						'data-testid': `menu.brand.${id}`,
						rel: 'follow',
					}}
					onClick={handleLinkClick}
				>
					{labelBrand}
				</ClientLink>
			) : (
				<button
					className={cx(
						css && css.join(' '),
						checkBrandSelected && styles.selected,
						styles.button
					)}
					data-testid={`menu.brand.${id}`}
					onClick={() => handleClick(id)}
				>
					{labelBrand}
				</button>
			)}

			<BrandsAccesibiltyButton
				id={id}
				label={labelBrand}
				refDropdown={refDropdown}
				handleOpenWithKeyboard={() => handleOpenWithKeyboard(id)}
				isDesktop={isLargeOrGreater}
				isFocused={isFocused}
				open={open}
				checkBrandSelected={checkBrandSelected}
			/>

			{open && (
				<BrandsSubmenu
					id={id}
					menus={menus}
					type={type}
					image={image}
					isMobile={!isLargeOrGreater}
					analyticEventData={analyticEventData}
					brandsRef={brandsRef}
				/>
			)}
		</li>
	)
}
