import { HeaderStateContext } from 'header/context/HeaderContext'
import { useContext } from 'react'

export const useHeaderState = () => {
	const context = useContext(HeaderStateContext)

	if (context === undefined) {
		throw new Error('useMenuState must be used within a HeaderProvider')
	}

	return context
}
