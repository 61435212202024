'use client'

import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'

import { type MenuEntry, MenuSectionType } from '../types/menu'

export function useCorrectUrlNewResponse(section: MenuEntry) {
	const { type, url, legacyUrl } = section

	const { enabled: FflagEditActivation } = useFeatureFlag(
		featureFlagKeys.EditActivation
	)
	const { enabled: FflagCatalogActivation } = useFeatureFlag(
		featureFlagKeys.PlpVersion
	)

	const featureFlagMap: { [key: string]: boolean } = {
		[MenuSectionType.Brand]: true,
		[MenuSectionType.Multibrand]: true,
		[MenuSectionType.Edit]: FflagEditActivation,
		[MenuSectionType.Catalog]: FflagCatalogActivation,
	}

	if (featureFlagMap[type]) {
		return url
	}

	return legacyUrl
}
