'use client'

import { useFieldValidations } from 'country-configuration/contexts/FieldValidationsProvider'
import { useForm } from 'form-components'
import { EmailInput } from 'form-components/components/EmailInput/EmailInput'
import { SubmitButton } from 'form-components/components/SubmitButton/SubmitButton'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { NEWSLETTER_POLICY_ID } from 'legal/constants/privacyPolicy'
import dynamic from 'next/dynamic'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { cx } from 'utils/cx'

import {
	FormFieldIds,
	FormFieldNames,
	NewsletterOrigin,
	TestIds,
	defaultValues,
} from '../../constants'
import { useSubscribe } from '../../hooks/useSubscribe'
import type { FormValues } from '../../types/form'
import { GenericError } from './GenericError/GenericError'

import styles from './Form.module.scss'

const LegalConfirmation = dynamic(() =>
	import('./LegalConfirmation/LegalConfirmation').then(
		(module) => module.LegalConfirmation
	)
)

const Feedback = dynamic(() =>
	import('../Feedback/Feedback').then((module) => module.Feedback)
)

type FormProps = {
	className?: string
	origin: NewsletterOrigin
	legalConfirmation?: boolean
}

export function Form({
	className,
	origin,
	legalConfirmation,
}: FormProps): React.ReactNode {
	const { t } = useLabels()
	const { subscribe, subscribeError, subscribeSuccess, resetSubscription } =
		useSubscribe(origin)

	const formMethods = useForm({
		defaultValues,
		mode: 'onTouched',
	})
	const { handleSubmit, setError, reset } = formMethods

	const fieldValidations = useFieldValidations()

	const onSubmit = (values: FormValues): Promise<void> =>
		subscribe(values[FormFieldNames.Email])

	useEffect(() => {
		if (subscribeError) {
			setError(subscribeError, {
				type: 'validate',
				message: t(`form.${subscribeError}.format.errormessage`),
			})
		}
	}, [subscribeError])

	useEffect(() => {
		if (subscribeSuccess) {
			reset()
		}
	}, [subscribeSuccess])

	return (
		<FormProvider {...formMethods}>
			<form
				className={cx(styles.form, className)}
				onSubmit={handleSubmit(onSubmit)}
			>
				<EmailInput
					name={FormFieldNames.Email}
					id={FormFieldIds.Email}
					rules={fieldValidations.email}
					data-testid={TestIds.EmailInput}
				/>
				{legalConfirmation && <LegalConfirmation />}
				<SubmitButton
					id={FormFieldIds.SubmitButton}
					label={t('footer.subscribe.button')}
					data-testid={TestIds.SubmitButton}
					aria-describedby={NEWSLETTER_POLICY_ID}
				/>
				<GenericError submitHandler={onSubmit} resetValues={reset} />
			</form>
			<Feedback
				sendSuccessFeedback={!!subscribeSuccess}
				onFeedbackSent={resetSubscription}
			/>
		</FormProvider>
	)
}
