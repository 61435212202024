import { HeaderDispatchContext } from 'header/context/HeaderContext'
import { useContext } from 'react'

export const useHeaderDispatch = () => {
	const context = useContext(HeaderDispatchContext)

	if (context === undefined) {
		throw new Error('useMenuDispatch must be used within a HeaderProvider')
	}

	return context
}
