import { useContext } from 'react'

import { MenuDataStateContext } from './MenuDataContext'

export const useMenuDataState = () => {
	const context = useContext(MenuDataStateContext)

	if (context === undefined) {
		throw new Error('useMenuState must be used within a MenuProvider')
	}

	return context
}
