'use client'

import { featureFlagKeys } from 'feature-flags/constants/featureFlags'

import {
	ClientFeatureFlagLink,
	type ClientFeatureFlagLinkProps,
} from '../../components/featureFlagLink/client'

export type MyAccountClientLinkProps = Omit<
	ClientFeatureFlagLinkProps,
	'featureFlagKey'
>

export function MyAccountClientLink({
	children,
	...linkProps
}: MyAccountClientLinkProps) {
	return (
		<ClientFeatureFlagLink
			{...linkProps}
			featureFlagKey={featureFlagKeys.MyAcountPage}
		>
			{children}
		</ClientFeatureFlagLink>
	)
}
