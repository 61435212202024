import type { SelectedMenusType } from 'header/types'
import {
	MenuLegacySectionType,
	type MenuLegacyType,
} from 'header/types/menu/menuLegacy'

import {
	getHeaderLocalStorage,
	setHeaderLocalStorage,
} from '../../headerLocalStorage'
import { validateSelectedMenus } from './validateSelectedMenus'

const getSectionIdWithClothesAndFirstWithMenus = (menus?: MenuLegacyType[]) => {
	if (!menus) {
		return undefined
	}

	const sectionIdWithClothes = menus.find(
		(menu) => menu.analyticLabel === 'clothing'
	)?.id
	const firstSectionIdWithMenus = menus.find((menu) => menu.menus)?.id

	return sectionIdWithClothes || firstSectionIdWithMenus
}

const generateSelectedMenus = (menus: MenuLegacyType[]) => {
	const generatedSelectedMenus: SelectedMenusType = {}

	menus.forEach((menuItem) => {
		if (
			menuItem.type === MenuLegacySectionType.Multibrand &&
			menuItem.menus &&
			menuItem.menus?.[0].subBrandId
		) {
			generatedSelectedMenus[menuItem.id] = {
				...generatedSelectedMenus[menuItem.id],
				selected: menuItem.menus?.[0].subBrandId,
				selectedSectionId: menuItem.menus?.[0].id,
			}

			menuItem.menus?.forEach((subBrandItem) => {
				const selectedSection = getSectionIdWithClothesAndFirstWithMenus(
					subBrandItem.menus
				)
				if (subBrandItem.subBrandId && selectedSection) {
					generatedSelectedMenus[menuItem.id] = {
						...generatedSelectedMenus[menuItem.id],
						[subBrandItem.subBrandId]: selectedSection,
					}
				}
			})
		} else {
			const selectedSection = getSectionIdWithClothesAndFirstWithMenus(
				menuItem.menus
			)

			if (menuItem.id && selectedSection) {
				generatedSelectedMenus[menuItem.id] = { selected: selectedSection }
			}
		}
	})
	return generatedSelectedMenus
}

const checkSectionExistsAndGetDefaultSection = (
	sectionMenus?: MenuLegacyType[],
	sectionId?: string
) => {
	if (!sectionMenus) {
		return undefined
	}

	const sectionExists = sectionMenus?.some((menu) => menu.id === sectionId)

	if (!sectionExists) {
		return getSectionIdWithClothesAndFirstWithMenus(sectionMenus)
	}

	return undefined
}

const changeSelectedMenus = (
	menus: MenuLegacyType[],
	selectedMenus: SelectedMenusType
) => {
	const handleSelectedSection = (
		brandId: string,
		brandMenu: MenuLegacyType,
		selectedMenu: {
			[subBrandId: string]: string | undefined
			selected?: string
			selectedSectionId?: string
		}
	) => {
		const sectionExists = brandMenu.menus?.some(
			(menu) => menu.id === selectedMenu.selectedSectionId
		)

		if (!sectionExists) {
			const selectedSection = brandMenu.menus?.find(
				(menu) => menu.subBrandId === selectedMenu.selected
			)?.id

			if (selectedSection) {
				changedSelectedMenus[brandId].selectedSectionId = selectedSection
			}
		}
	}

	const handleMultibrand = (
		brandId: string,
		brandMenu: MenuLegacyType,
		selectedMenu: {
			[subBrandId: string]: string | undefined
			selected?: string
			selectedSectionId?: string
		}
	) => {
		handleSelectedSection(brandId, brandMenu, selectedMenu)

		Object.keys(selectedMenu).forEach((subBrandId) => {
			if (subBrandId !== 'selected' && subBrandId !== 'selectedSectionId') {
				const subBrandMenu = brandMenu.menus?.find(
					(menu) => menu.subBrandId === subBrandId
				) as MenuLegacyType

				if (subBrandMenu) {
					const selectedSection = checkSectionExistsAndGetDefaultSection(
						subBrandMenu.menus,
						selectedMenu[subBrandId]
					)

					if (selectedSection) {
						changedSelectedMenus[brandId] = {
							...changedSelectedMenus[brandId],
							[subBrandId]: selectedSection,
						}
					}
				}
			}
		})
	}

	const handleSingleBrand = (
		brandId: string,
		brandMenu: MenuLegacyType,
		selectedMenu: {
			[subBrandId: string]: string | undefined
			selected?: string
			selectedSectionId?: string
		}
	) => {
		const selectedSection = checkSectionExistsAndGetDefaultSection(
			brandMenu.menus,
			selectedMenu.selected
		)

		if (selectedSection) {
			changedSelectedMenus[brandId] = {
				selected: selectedSection,
			}
		}
	}

	const changedSelectedMenus = selectedMenus

	Object.keys(selectedMenus).forEach((brandId: string) => {
		const selectedMenu = selectedMenus[brandId]
		const brandMenu = menus.find((menu) => menu.id === brandId)

		if (brandMenu) {
			if (brandMenu.type === MenuLegacySectionType.Multibrand) {
				handleMultibrand(brandId, brandMenu, selectedMenu)
			} else {
				handleSingleBrand(brandId, brandMenu, selectedMenu)
			}
		}
	})

	return changedSelectedMenus
}

export const checkSelectedMenu = (menus: MenuLegacyType[]) => {
	const selectedMenus = getHeaderLocalStorage('selectedMenus')

	const localStorageValue = validateSelectedMenus(menus, selectedMenus)
		? changeSelectedMenus(menus, selectedMenus)
		: generateSelectedMenus(menus)

	setHeaderLocalStorage('selectedMenus', localStorageValue)
}
