// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconLogoMng = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			role='img'
			aria-hidden={!alt ? 'true' : undefined}
			className={computedClassName}
			data-testid='logo-mng'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			viewBox='0 0 53.559 15.306'
		>
			<path d='M13.653 14.977h2.458V.322h-2.509L9.382 7.2 10.8 9.45l2.853-4.547Zm-11.189 0V4.903l5.594 8.944 1.427-2.282L2.486.322H0v14.655Zm29.232-7.539 2.465 2.475V.322h-2.465zm-11.938 7.539h2.466V4.027l10.102 10.95h1.835v-1.78L22.266.321h-2.508ZM51.087 4.39c-1.173-1.219-2.983-2-5.013-2-3.241 0-5.92 1.99-6.346 4.57h-2.719c.354-4.006 4.455-6.96 9.1-6.96 2.758 0 5.237 1.028 6.905 2.681zm-5.243 8.54c-2.704 0-5.007-1.465-5.867-3.516h-2.768c.916 3.535 4.515 5.892 8.79 5.892a13.5 13.5 0 0 0 3.08-.333c2.139-.53 4.48-1.92 4.48-4.116V6.961h-7.743v2.453h5.273v1.12c0 1.229-1.837 1.94-2.961 2.19a10 10 0 0 1-2.019.206z' />
			{alt && <title lang={lang}>{alt}</title>}
		</svg>
	)
}
