'use client'

import { IconDownSmall } from 'icons/components/IconDownSmall'
import { IconUpSmall } from 'icons/components/IconUpSmall'
import { cx } from 'utils/cx'

import styles from './Brands.module.scss'

interface BrandsAccesibiltyButton {
	id: string
	label?: string
	refDropdown: React.RefObject<HTMLButtonElement>
	handleOpenWithKeyboard: (brand: string) => void
	isDesktop: boolean
	isFocused: boolean
	open: boolean
	checkBrandSelected: boolean
}

export const BrandsAccesibiltyButton = ({
	id,
	label,
	refDropdown,
	handleOpenWithKeyboard,
	isDesktop,
	isFocused,
	open,
	checkBrandSelected,
}: BrandsAccesibiltyButton) => {
	const isOpen = open && checkBrandSelected
	return (
		<button
			ref={refDropdown}
			className={cx(
				styles.dropdown,
				!isDesktop && styles.dropdownHide,
				isFocused && styles.dropdownFocused
			)}
			onClick={() => handleOpenWithKeyboard(id)}
			aria-label={label}
			aria-expanded={isOpen}
			aria-haspopup='menu'
			aria-controls={`${id}Menu`}
		>
			{isOpen ? <IconUpSmall /> : <IconDownSmall />}
		</button>
	)
}
