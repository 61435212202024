export type MenuEntry = {
	id: string
	labelId: string
	subLabelId?: string
	catalogId?: string
	url?: string
	legacyUrl?: string
	menus?: MenuEntry[]
	type: MenuSectionType
	images?: MenuImageDevice
	style?: string[]
	hideIn?: HideIn
}

export enum MenuSectionType {
	Brand = 'BRAND',
	Multibrand = 'MULTIBRAND',
	Catalog = 'CATALOG',
	Edit = 'EDIT',
	Group = 'GROUP',
	Collections = 'COLLECTIONS',
	Featured = 'FEATURED',
}

interface HideIn {
	desktop: boolean
	mobile: boolean
}

interface MenuImageDevice {
	mobile?: { src: string; fontColor: string }
	desktop?: { src: string }
}

export interface MenuService {
	menus: MenuEntry[]
}

export type EntryClickMenuEventData = {
	brand: string
	subbrand?: string
	section: string
	subsection?: string
}

export type EntryParent = {
	id: string
	labelId: string
}
