import { CacheClass } from 'cache/cache'

import { CACHE_KEY } from '../constants/constants'

let cacheInstance: CacheClass

export const shoppingCartCache = (
	token?: string,
	countryISO?: string
): CacheClass => {
	if (token || countryISO) {
		cacheInstance = new CacheClass({
			cacheKey: CACHE_KEY,
			maxDiffTimestamp: 60000,
			isTokenCache: true,
			isCountryCache: true,
			token,
			countryISO,
		})
	}
	return cacheInstance
}
