'use client'

import { useLogoProperties } from './hooks/useLogoProperties'

type LogoSize = {
	width: number
	height: number
}

type LogoProps = {
	className?: string
	size?: LogoSize
}

export function Logo({ className = '', size }: LogoProps) {
	const { LogoComponent, name, altText, width, height } = useLogoProperties()

	return (
		<LogoComponent
			className={className}
			lang='en'
			alt={altText}
			width={size?.width || width}
			height={size?.height || height}
			data-testid={name}
		/>
	)
}
